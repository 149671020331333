@charset "utf-8";

// ======== All Device

//hタイトル設定
h1:not(.title) {
  text-align: center;
  position: relative;
  @include fontsize(36);
  font-weight: bold;
  height: 160px;
  color: $color_h1_text;
  background: url(/wp-content/uploads/h1_bg.jpg) no-repeat center;
  background-size: cover;
  padding-top:55px;
  margin: 0 0 .5em;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

h2 {
  display: table;
  table-layout: fixed;
  margin: 0 auto 1.5em !important;
  padding-bottom: 25px;
  color: $color_h2_text;
  font-weight: bold;
  @include fontsize(24);
  position: relative;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 78px;
    height: 2px;
    background: url(/wp-content/uploads/h2_line.jpg) no-repeat center;
  }
}

//top_section1
.top_section1{
  font-weight:bold;
  font-size:16px;
  position: relative;
  line-height:2;
  background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding:16em 0;
  margin:0 auto;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 15%;
    left:50%;
    transform: translate(-50%,-50%);
    background: url(/wp-content/uploads/beef01.png) no-repeat center;
    width:262px;
    height:200px;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    bottom: -15%;
    left:50%;
    transform: translate(-50%, -80%);
    height: 2px;
    background: url(/wp-content/uploads/beef2.png) no-repeat center;
    width:270px;
    height:206px;
  }
  .col{
    position: relative;
    z-index:5;
  }
}

//top_section2
.top_section2{
  position: relative;
  line-height:2;
  background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding:5em 0;
  margin:0 auto !important;

  .col{
    position: relative;
  }

a{
  .borderbox{
    width:300px;
    height:100%;
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
  }
}

a:hover{
  .borderbox{
    border: 5px solid #1a1a1a;
  }
}

.fig_box1{
  max-width:300px;
  margin:0 auto;
}

.fig_box2{
  max-width:300px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
}

.fig_box3{
  max-width:300px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
}

.fig_box4{
  max-width:300px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
}

.fig_text1{
  background:  #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
  height:90px;
  width:100%;
  margin:0;
}

.fig_text2{
  background:  #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
  height:90px;
  width:100%;
  margin:0;
}

.fig_text3{
  background:  #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
  height:90px;
  width:100%;
  margin:0;
}
}

//top_section3
.top_section3{
  figcaption{
    text-align: center !important;
  }
}

//top_section4
.top_section4{
  margin: 0 auto;
  .news {
    // padding: 4% 0;
    .news_bg {
      background: $color_news_bg;
    }
    &.typeC {
      .news_bg {
        padding: 1em;
        background-color: #fff;
      }
    }
    h2 {
      color: $color_news_header;
    }
    dl {
      margin: 0;
      dt {
        margin: 0.5em 0 0;
        padding: 0 1em;
        font-weight: normal;
      }
      dd {
        margin: 0.5em 0;
        padding: 0 1em 0.5em;
        /*border-bottom: $style_news_border;*/
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media ( min-width : 768px ) {

    .news {
      &.typeA {
        > div {
          display: table;
          width: 100%;
          margin: 0 auto;
          .heading {
            display: table-cell;
            width: 23.5%;
            vertical-align: middle;
          }
        }
        h2 {
          margin: 0;
          padding: 0;
          &:after {
            display: none;
          }
        }
        .content {
          display: table-cell;
          overflow: hidden;
          width: 74.5%;
          padding-left: 2%;
          vertical-align: middle;
        }
      }
      &.typeC {
        .news_bg {
          height: 400px;
          padding: 10px;
        }
        dl {
          max-height: 350px;
        }
      }
      dl {
        overflow-y: auto;
        max-height: 200px;
        dt {
          width: 100px;
          clear: left;
          float: left;
          margin: 0;
          padding: 1em 0;
        }
        dd {
          margin: 0;
          padding: 1em 0 1em 0;
        }
        dt + dd {
          padding-left: 120px;
        }
      }
    }

  }

}

//top_section5
.top_section5{
  margin:0 auto;
  padding:4em 0;
  background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
}

//top_section6
.top_section6{
  border-top: 1px solid #1a1a1a;
  padding-top:2em;
  .sns_icons {
    display: table;
    @include list-horizontal();
    margin: auto;
    li {
      display: inline-block;
    }
    li:not(:last-child) {
      margin-right: 10px;
    }
    a {
      display: block;
      @include image-replace();
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      &.icon_facebook {
        background-image: url(/wp-content/uploads/facebook.png) !important;
      }
      &.icon_twitter {
        background-image: url(/wp-content/uploads/twitter.png) !important;
      }
    }
  }
  footer .sns_icons {
    margin-top: 1.5em;
  }

  @media ( min-width : 768px ) {
    .sns_icons {
      margin: 0;
      &.center {
        margin: auto;
      }
      li:not(:last-child) {
        margin-right: 20px;
      }
      a {
        width: 50px;
        height: 50px;
      }
    }
    footer .sns_icons {
      margin-top: 30px;
    }
  }
}

//snsカスタム
.top_section6{
  border-top: 1px solid #1a1a1a;
  padding-top:2em;
  .sns_icons {
    display: table;
    @include list-horizontal();
    margin: auto;
    li {
      display: inline-block;
      padding:0 1em;
    }
    li:not(:last-child) {
      margin-right: 10px;
    }
    a {
      display: block;
      @include image-replace();
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      &.icon_facebook {
        background-image: url(/wp-content/uploads/facebook.png) !important;
      }
      &.icon_twitter {
        background-image: url(/wp-content/uploads/twitter.png) !important;
      }
    }
  }
  footer .sns_icons {
    margin-top: 1.5em;
  }

  @media ( min-width : 768px ) {
    .sns_icons {
      margin: 0;
      &.center {
        margin: auto;
      }
      li:not(:last-child) {
        margin-right: 20px;
      }
      a {
        width: 50px;
        height: 50px;
      }
    }
    footer .sns_icons {
      margin-top: 30px;
    }
  }
}


//その他
.color_box1{
  font-weight: bold;
  color:#fff;
  background-color: $color_primary;
  padding:15px 10px;
  width:300px;
  margin:0 auto;
  text-align: center;
}

// ======== Mobile
@media ( max-width : 480px ) {

} // @media

// ======== Tablet & PC
@media ( min-width : 768px ) {
.primary_header{
  .row:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.nav_custom{
  margin: 0 !important;
}

.global_nav{
  background-color: #1a1a1a;
}

//top_section1
.top_section1{
  font-weight:bold;
  font-size:16px;
  position: relative;
  line-height:2;
  background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding:5em 0;
  margin:0 auto;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left:0;
    right:auto;
    transform: translateY(-50%);
    background: url(/wp-content/uploads/beef01.png) no-repeat center;
    width:262px;
    height:200px;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right:0;
    left:auto;
    transform: translateY(-50%);
    height: 2px;
    background: url(/wp-content/uploads/beef2.png) no-repeat center;
    width:270px;
    height:206px;
  }
  .col{
    position: relative;
    z-index:5;
  }
}

//top_section2
.top_section2{
  position: relative;
  line-height:2;
  background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding:5em 0;
  margin:0 auto !important;

  .col{
    position: relative;
  }

a{
  .borderbox{
    width:250 px;
    height:100%;
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
  }
}

a:hover{
  .borderbox{
    border: 5px solid #1a1a1a;
  }
}

.fig_box1{
  max-width:250px;
  margin:0 auto;
}

.fig_box2{
  max-width:250px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
}

.fig_box3{
  max-width:250px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
}

.fig_box4{
  max-width:250px;
  margin:0 auto;
  height:180px;
  padding-top:75px;
  background:  #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
}

.fig_text1{
  background:  #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
  background-size: 70%;
  height:90px;
  width:100%;
  margin:0;
}

.fig_text2{
  background:  #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
  background-size: 70%;
  height:90px;
  width:100%;
  margin:0;
}

.fig_text3{
  background:  #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
  background-size: 70%;
  height:90px;
  width:100%;
  margin:0;
}
}

// カルーセル
@charset "UTF-8";

.recommend {
  padding: 5% 0;
  margin-bottom: 0;
  /* slick */
  .slick-slider {
    overflow: hidden;
  }

  .slick-slide {
    margin: 0 0.2%;
  }

  .slick-slide a:focus {
    outline: 0;
  }

  .slick-next,
  .slick-prev {
    z-index: 10;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 10%;
      margin: auto;
      width: 20px;
      height: 20px;
      z-index: 10;
      border-top: 3px solid #1a1a1a;
      border-right: 3px solid #1a1a1a;
      transition: 0.2s;
    }
  }

  .slick-prev {
    left: 40px;
    top:35%;

    &:before {
      left: 5%;
      transform: translate(0, -50%) rotate(-135deg);
    }
  }

  .slick-next {
    right: 40px;
    top:35%;

    &:before {
      right: 5%;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  // figure {
  //   img {
  //     max-width: 100%;
  //     width: 100%;
  //   }
  // }

  .more {
    display: block;
    margin: 0 auto;
    text-align: center;

    a {
      display: block;
      margin: 0 auto;
      max-width: 235px;
      width: 100%;
    }
  }
  .item_photo {
    a {
      position: relative;
      display: block;
      padding-top: ($carousel01_height / $carousel01_width)*100 + %;
      overflow: hidden;
      img {
        max-width: none;
        width: auto;
        position: absolute;
        top: -300%;
        left: -300%;
        right: -300%;
        bottom: -300%;
        margin: auto;
        height: 100%;
      }
    }
  }
}


//その他
.color_box1{
  width:450px !important;
  padding:15px 10px;
}

} // @media

// ======== PC
@media ( min-width : 1024px ) {

  //top_section1
  .top_section1{
    font-weight:bold;
    font-size:16px;
    position: relative;
    line-height:2;
    background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding:5em 0;
    margin:0 auto;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left:10%;
      transform: translateY(-50%);
      background: url(/wp-content/uploads/beef01.png) no-repeat center;
      width:262px;
      height:200px;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right:10%;
      transform: translateY(-50%);
      height: 2px;
      background: url(/wp-content/uploads/beef2.png) no-repeat center;
      width:270px;
      height:206px;
    }
    .col{
      position: relative;
      z-index:5;
    }
  }

} // @media

@media ( min-width : 920px ) {

  //top_section2
  .top_section2{
    position: relative;
    line-height:2;
    background:  url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding:5em 0;
    margin:0 auto !important;

    .col{
      position: relative;
    }

  a{
    .borderbox{
      width:300px;
      height:100%;
      position: absolute;
      top:0;
      left:50%;
      transform: translateX(-50%);
    }
  }

  a:hover{
    .borderbox{
      border: 5px solid #1a1a1a;
    }
  }

  .fig_box1{
    max-width:300px;
    margin:0 auto;
  }

  .fig_box2{
    max-width:300px;
    margin:0 auto;
    height:180px;
    padding-top:75px;
    background:  #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
  }

  .fig_box3{
    max-width:300px;
    margin:0 auto;
    height:180px;
    padding-top:75px;
    background:  #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
  }

  .fig_box4{
    max-width:300px;
    margin:0 auto;
    height:180px;
    padding-top:75px;
    background:  #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
  }

  .fig_text1{
    background:  #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
    height:90px;
    width:100%;
    margin:0;
  }

  .fig_text2{
    background:  #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
    height:90px;
    width:100%;
    margin:0;
  }

  .fig_text3{
    background:  #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
    height:90px;
    width:100%;
    margin:0;
  }
  }


} // @media
