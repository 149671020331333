@charset "utf-8";

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.lead {
  color: $color_primary;
  @include fontsize(20);
}
.text-xxlarge {
  @include fontsize(24);
}
.text-xlarge {
  @include fontsize(20);
}
.text-large {
  @include fontsize(16);
}
.text-slarge {
  @include fontsize(16);
}
.text-small {
  @include fontsize(12);
}
.text-xsmall {
  @include fontsize(10);
}

// 文字色(màu text)
.text-white {
  @if variable-exists(color_white) {
    color: $color_white !important;
  } @else {
    color: #fff !important;
  }
}
.text-black {
  @if variable-exists(color_black) {
    color: $color_black !important;
  } @else {
    color: #000 !important;
  }
}
.text-red {
  @if variable-exists(color_red) {
    color: $color_red !important;
  } @else {
    color: #f00 !important;
  }
}
.text-primary {
  color: $color_primary !important;
}

@media ( max-width : 767px ) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media ( min-width : 768px ) {
  .lead {
    @include fontsize(28);
  }
  .text-xxlarge {
    @include fontsize(36);
  }
  .text-xlarge {
    @include fontsize(28);
  }
  .text-large {
    @include fontsize(20);
  }
  .text-small {
    @include fontsize(12);
  }
  .text-xsmall {
    @include fontsize(10);
  }

  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media ( min-width : 768px ) and ( max-width : 1023px ) {
  .tablet-hide {
    display: none !important;
  }
}

@media ( min-width : 1024px ) {
  .tablet-only {
    display: none !important;
  }
}

.arial {font-family: 'Arial', arial!important}
.arial_n {font-family: 'Arial Narrow', arial!important}

.color_pri {color: $color_primary}
.mb0 {margin-bottom: 0!important}
