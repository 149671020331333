@charset "utf-8";

$search_area_bg: #1a1a1a  ;
$search_area_width: 250;
$search_input_bg_color: #fff;
$search_input_text_color: $color_text;
$search_submit_bg_color: #1a1a1a;
$search_submit_text_color: #fff;
$search_icn_width: 30;
$search_icn_height: 30;
$search_icn_uri: '/wp-content/uploads/search.png'; //　dataURIもしくはurlをドメイン抜きの絶対パスで入力(nhập vào dataURI hoặc url bằng path tuyệt đối sau khi đã xóa domain)

.epress_search {
  padding: 3px;
  width: 100%;
  form {
    overflow: hidden;
    border: 4px solid $search_area_bg;
  }
  input[type="text"],
  input[type="submit"] {
  	margin: 0;
  	padding: 0;
    float: left;
  	background: none;
  	border: none;
  	border-radius: 0;
  	outline: none;
    appearance: none;
  }
  input[type="text"] {
    background: $search_input_bg_color;
    color: $search_input_text_color;
    padding: .3em .3em .3em .7em;
    width: 70%;
  }
  input[type="submit"] {
    cursor: pointer;
    padding: .5em 1em;
    background: $search_submit_bg_color;
    color: $search_submit_text_color;
    @include fontsize(12);
    width: 30%;
  }
}

@media ( max-width : ($header_break_point - 1)+px ) {
  header .global_nav > ul > li {
    &.gnav_search {
      position: static;
      >a {
        display: none!important;
      }
      .epress_search {
        display: block!important;
        position: static!important;
        width: 100%!important;
        background: $color_gnav_bg;
      }
    }
  }
}

/*
 * ヘッダーナビのデザイン(design của header navi)
 */
 .epress_search {
   form {
     @if $header_search_design != 'A' {
       border: 2px solid $search_area_bg;
       border-radius: 20px;
       padding: 0 5px;
     }
   }
   input[type="text"],
   input[type="submit"] {
     @if $header_search_design == 'B' {
       float: right;
     }
   }
   input[type="text"] {
     @if $header_search_design != 'A' {
       $val: $search_icn_width+px;
       width: calc(100% - #{$val});
     }
   }
   input[type="submit"] {
     @if $header_search_design != 'A' {
       @include image-replace();
       background: url($search_icn_uri) no-repeat center center;
       padding: .4em 0;
       width: $search_icn_width+px;
       height: $search_icn_height+px;
     }
   }
 }

 /*
  * ヘッダの検索表示タイプ(type hiển thị tìm kiếm của header)
  */
header .global_nav > ul > li {
  &.gnav_search {
    @if $header_search == 'A' {
      position: relative;
    }
    a {
      @if $header_search == 'A' {
        display: block;
        background: url($search_icn_uri) no-repeat center center;
        @include image-replace();
        width: $search_icn_width+px;
        height: $search_icn_height+px;
        &:hover {
          border-bottom: 0;
        }
      } @else {
        display: none;
      }
    }
    .epress_search {
      @if $header_search == 'A' {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        @media (min-width: $header_break_point+px) {
          width: $search_area_width+px;
        }
      } @else if $header_search == 'B' {
        position: absolute;
        top: 10px;
        right: 3%;
        @media (min-width: $header_break_point+px) {
          width: $search_area_width+px;
        }
      }
      form {
        background: $color_header_bg;
      }
    }
  }
}

@if $header_type == 'bottom' {
  @media (min-width: $header_break_point+px) {
    body.home header {
      &.low {
        .global_nav > ul > li.gnav_search .epress_search {
          top: auto;
          bottom: 100%;
        }
      }
      &.high {
        .global_nav > ul > li.gnav_search .epress_search {
          top: 100%;
        }
      }
    }
  }
}
