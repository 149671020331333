@charset "utf-8";

// ======== 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_body_bg: #ffffff !default;
$color_highlight_bg: rgba($color_primary, .05) !default;
$color_inquiry_bg: $color_highlight_bg !default;
$color_text: #fff !default;
$header_border_width: 0px !default;
$header_border_position: top !default;
$header_border_color: $color_primary !default;
$color_header_bg: $color_primary !default;
$color_header_text: $color_text !default;
$color_hamburger: $color_text !default;
$color_navlink_pc: $color_text !default;
$color_navlink_pc_hover: rgba(#fff, .7) !default;
$color_gnav_bg: rgba($color_primary, .9) !default;
$color_gnav_border_sp: #333 !default;
$color_navlink_sp: #fff !default;
$color_navlink_sp_active: #fff !default;
$color_navlink_sp_active_bg: $color_primary !default;

$base_font_size: 14 !default;
$design_concept: 'material' !default;
$container: 1200 !default;
$header_break_point: 769 !default;
$fixed_header: true !default;
$header_type: 'top' !default;
$navi_type_sp: 'y' !default;
$logo_width: 370 !default;
$logo_height: 30 !default;
$logo_sp_width: 250 !default;
$header_height: 120 !default;
$header_logo_type: 'B';
$header_alpha: false !default;
$header_search: 'A' !default;
$header_search_design: 'A' !default;
$main_slide_width: 1920 !default;
$main_slide_height: 650 !default;
$main_slide_correct: 2 !default;
$main_slide_break_point: 600 !default;
$main_slide_type: 'C' !default;
$navi_event_type: 'click' !default;
// ======== 管理用データここまで


header {
  position: relative;
  z-index: 100;
  width: 100%;
  background: $color_header_bg;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  @if $header_border_width != 0px {
    $header_border_style: $header_border_width solid $header_border_color;
    @if $header_border_position == top {
      border-top: $header_border_style;
    } @else if $header_border_position == bottom {
      border-bottom: $header_border_style;
    }
  }

  @if $main_slide_type == 'B' or $header_alpha == true {
    body.home.scrollTop & {
      box-shadow: none;
    }
  }

  section {
    margin: 0;
    width: 100%;
  }
  .row {
    margin: 0 3%;
    @media ( max-width : $header_break_point+px ) {
      margin-bottom: 0!important;
    }
  }

  // Primary
  .primary_header {
    color: $color_header_text;
    background: url(/wp-content/uploads/hdr_line.jpg) repeat-x top;
  }

  // Logo
  .title {
    max-width: 40%;
    margin: 0;
    width:20%;
    padding: 15px 1% 10px 1%;
    line-height: 1;
    text-align: left;
    @include fontsize(10);
    @if $header_logo_type != 'A' {
      max-width: none;
      width: 100%;
      @media(max-width: 600px) {
        width: 90%;
      }
      object {
        max-width: 240px;
      }
    }
    img {
      width: auto;
      max-width: 100%;
      vertical-align: middle;
    }
    a {
      display: inline-block;
    }
    object {
      pointer-events: none;
      width: $logo_width+px;
      height: $logo_height+px;
      @media(max-width: 600px) {
        width: $logo_sp_width+px;
        height: ($logo_height/$logo_width)*$logo_sp_width+px;
        min-height: 45px;
      }
    }
  }

  // Global Navigation
  .global_nav {
    @if $navi_event_type != 'click' {
      list-style-position: inside;
    }
    &:not(:last-child) {
      display: none;
    }
    >ul {
      position: absolute;
      z-index: -1;
      top: 100%;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      @media(min-width: 768px){
        left: auto;
        width: 50%;
        max-width: 400px;
      }
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @include listclear();
      border-top: 1px solid $color_gnav_border_sp;
      >li {
        border-bottom: 1px solid $color_gnav_border_sp;
        a {
          display: block;
          padding: 1.5em 1em;
          text-decoration: none;
          color: $color_navlink_sp;
          background: $color_gnav_bg;
          &:hover {
            color: $color_navlink_sp;
          }
          &:active {
            background: $color_navlink_sp_active_bg;
            color: $color_navlink_sp_active;
          }
        }
        >a {
          background: $color_gnav_bg;
        }
      }
    }
    // ハンバーガーメニュー(humburger menu)
    .menu_icon {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      a {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        transition: transform .3s;
        overflow: hidden;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: 4px;
          background: $color_hamburger;
          transition: transform .5s;
        }
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
        span {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: 4px;
          background: $color_hamburger;
          transition: transform .5s;
          @include image-replace();
        }
        &.close:not(.fix) {
          background: none;
          &:before {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            background: $color_hamburger;
          }
          &:after {
            bottom: 50%;
            transform: translate(0, 50%) rotate(-45deg);
            background: $color_hamburger;
          }
          span {
            transform: translate(100%, -50%);
          }
        }
      }
    }
  }
  .global_nav {
    >ul {
      transform-origin: center top;
      visibility: hidden;
      transition: transform .3s;
      @if $navi_type_sp == 'x' {
        transform: translateX(100%);
      }@else{
        transform: rotateX(90deg);
      }
    }
  }
  &.menu_open {
    .global_nav {
      >ul {
        visibility: visible;
        @if $navi_type_sp == 'x' {
          transform: translateX(0);
        }@else{
          transform: rotateX(0deg);
        }
      }
    }
  }
}

// 固定ヘッダ(header cố định)
@if $fixed_header != false and $header_type != 'bottom_logo_top' {
  body {
    header {
      transition: top .5s;
    }
    &.scroll {
      &.down header {
        position: absolute;
        top: 0;
        left: 0;
        &.high {
          position: fixed;
        }
      }
      header {
        z-index: 0;
        &.hidden {
          top: -100%;
        }
      }
    }
  }
  header.menu_open,
  body.up header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

#google_translate_element, .translate_gt {
  position: absolute;
  top: 50%;
  right: 15%;
  margin-top: -12px;
}
.translate_gt {
  margin-top: -14px;
}

@media ( min-width : $header_break_point+px ) {

  body {
    @if $header_alpha != false {
      &.home {
        padding-top: 0!important;
      }
    }
    &.scrollTop {

      &.home {

        header {
          @if $main_slide_type == 'B' or $header_alpha != false {
            background: transparent;
          }
        }
      }
    }
  }

  header {

    // Primary
    .primary_header {
    }

    .container {
     .row {
        display: table;
        width: 100%;
        margin-bottom: 0;
      }
    }
    .row {
      margin: 0 3%;
      @if $header_logo_type != 'B' {
        display: table;
        margin: 0 3%;
        width: 94%;
        @if $header_logo_type == 'A' {
          table-layout: fixed;
        }
      }
      // @if $header_logo_type != 'A' {
      //   height: $header_height+px;
      // }
    }
    .title, .global_nav {
      @if $header_logo_type == 'A' {
        height: $header_height+px;
      }
      @if $header_logo_type != 'B' {
        display: table-cell;
      }
      margin: 0;
      vertical-align: middle;
    }

    // Logo
    .title {
      max-width: none;
      width: $logo_width+px;
      max-width: none;
      padding: 30px 0;
      @if $header_logo_type == 'B' {
        width: 100%;
        text-align: left;
      }
      @if $header_logo_type == 'C' {
        text-align: center;
      }
      @if $header_logo_type != 'A' {
        object {
          max-width: 100%;
        }
      }
      img {
        width: auto;
      }
    }

    // Global Navigation
    .global_nav {
      text-align: right;
      $val: $logo_width+px;
      width: calc(94vw - #{$val});
      padding: 0;
      @if $header_logo_type == 'B' {
        padding: 10px 0;
        width: 100%;
        text-align: center;
      }
      @if $header_logo_type == 'C' {
        $val: $logo_width+px;
        width: calc((100% - #{$val}) / 2);
        text-align: center;
      }
      @if $header_search == 'B' {
        vertical-align: bottom;
        padding-bottom: 1em;
      }
      &:not(:last-child) {
        display: table-cell;
      }
      &:last-child {
        >ul {
          >li {
            @if $header_logo_type == 'C' {
              &:nth-child(-n+3),
              &:nth-child(n+7) {
                display: none;
              }
            }
          }
        }
      }
      >ul {
        position: static;
        display: inline-block;
        width: auto;
        @if $header_logo_type == 'A' {
          padding-left: 20px;
        }
        border: none;
        background: none;
        visibility: visible;
        @include fontsize(0);
        transform: none;
        text-align: left;
        overflow: visible;
        max-width: none;
        >li {
          display: inline-block;
          vertical-align: middle;
          border: none;
          line-height: 1.5;
          padding: .3em 1.2em;
          border-left: 1px;
          @include fontsize($base_font_size);
          >a {
            display: inline;
            padding: 0;
            color: $color_navlink_pc;
            text-decoration: none;
            background: transparent;
            &:hover {
              /*border-bottom: 2px solid $color_navlink_pc_hover;*/
              color: $color_navlink_pc_hover;
            }
            &:active {
              background: none;
            }
          }
          a {
            padding: .3em 0;
            transition: .3s;
          }
          &:last-child {
            border-right: 1px;
          }
        }
      }
      .menu_icon {
        display: none;
      }
    }
  }

  #google_translate_element, .translate_gt {
    position: static;
    float: right;
    margin-top: 3px;
    margin-left: 1em;
  }
  .translate_gt {
    margin-top: 0;
  }

  body {
    &.tablet {
      header {
        .global_nav {
          @if $header_logo_type == 'A' {
            $val: $logo_width+px;
            width: calc(962px - #{$val}); // for old iOS landscape
            width: calc(94vw - #{$val});
          }
        }
      }
    }
  }

}

@media ( max-width : 767px ) {
  header .global_nav > ul > li > a {
    >br,
    >span {
      display: none;
    }
  }
}

@if $header_type == 'bottom' or $header_type == 'bottom_logo_top' {

  @media (min-width: $header_break_point+px) {
    body.home {
      header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        @if $main_slide_type == 'B' {
          $val: $header_height+px;
          margin-top: calc(100vh - #{$val});
          @if $header_type == 'bottom_logo_top' {
            .title {
              display: block;
              position: absolute;
              margin-top: calc(-100vh + #{$val});
            }
            .global_nav {
              width: 100%;
              text-align: center;
            }
            .hdr_info {
              top: auto;
              right: 3%;
              margin-top: calc(-100vh + #{$val});
            }
          }
        } @else if $main_slide_type == 'D' {
          margin-top: ($main_slide_height / $main_slide_width)*100*.8 + %;
        } @else {
          margin-top: ($main_slide_height / $main_slide_width)*100 + %;
          @if $header_type == 'bottom_logo_top' {
            .title {
              display: block;
              position: absolute;
              margin-top: -($main_slide_height / $main_slide_width)*100 + %;
            }
            .global_nav {
              width: 100%;
              text-align: center;
            }
            .hdr_info {
              top: auto;
              right: 3%;
              margin-top: -($main_slide_height / $main_slide_width)*100 + %;
            }
          }
        }
      }

      @if $main_slide_type == 'B' {
        @media ( max-width : $main_slide_break_point+px ) {
          header {
            margin-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
          }
        }
      }

      @if $main_slide_type == 'C' {
        header {
          margin-top: ($main_slide_height / $container)*100 + %;
          @if $header_type == 'bottom_logo_top' {
            .title {
              display: block;
              position: absolute;
              margin-top: -($main_slide_height / $container)*100 + %;
            }
            .hdr_info {
              top: auto;
              right: 3%;
              margin-top: -($main_slide_height / $container)*100 + %;
            }
          }
        }
        @media (min-width: $container+px) {
          header {
            margin-top: $main_slide_height+px;
            @if $header_type == 'bottom_logo_top' {
              .title {
                display: block;
                position: absolute;
                margin-top: -$main_slide_height+px;
              }
              .hdr_info {
                top: auto;
                right: 3%;
                margin-top: -($main_slide_height - 25)+px;
              }
            }
          }
        }
        @media (min-width: $main_slide_width+px) {
          header {
            margin-top: ($main_slide_height / $main_slide_width)*100 + %;
            @if $header_type == 'bottom_logo_top' {
              .title {
                display: block;
                position: absolute;
                margin-top: -($main_slide_height / $main_slide_width)*100 + %;
              }
              .hdr_info {
                top: auto;
                right: 3%;
                margin-top: -($main_slide_height / $main_slide_width)*100 + %;
              }
            }
          }
        }
        @media ( max-width : $main_slide_break_point+px ) {
          header {
            margin-top: (($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
            @if $header_type == 'bottom_logo_top' {
              .title {
                display: block;
                position: absolute;
                margin-top: -(($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
              }
              .hdr_info {
                top: auto;
                right: 3%;
                margin-top: -(($main_slide_height / $main_slide_width)*100)*$main_slide_correct + %;
              }
            }
          }
        }
      }

      @if $fixed_header != false and $header_type != 'bottom_logo_top' {
        padding-top: 0!important;
        header {
          position: fixed;
          transition: none;
        }
        &.scroll {
          header {

            &.low {
            }
            &.hidden {
              top: 0;
            }
          }
        }
        &.up {
          header {

            &.low {
              position: absolute;
            }
            &.high {
              position: fixed;
              margin-top: 0;
              transition: .5s;
            }
          }
        }
        &.down {
          header {

            &.low {

            }
            &.high {
              position: fixed;
              margin-top: 0;
              &.hidden {
                top: -100%;
              }
            }
          }
        }
      }
    }
  }
}

.hdr_info {
  display: none;
  @media (min-width: $header_break_point+px) {
    display: block;
    position: absolute;
    top: 35px;
    right: 3%;
    border-left: solid 4px #1a1a1a;
    padding-left:10px;
    color:#000 !important;
    line-height: 1.6;
    a{
      text-decoration: none;
    }
  }
}
