@charset "utf-8";
// 設定ファイルここから
// 以下の画像サイズ設定は画像の比率決定のために使用します。
$carousel01_width: 800; //画像のwidth
$carousel01_height: 600; //画像のheight

@mixin olive_button() {
  display: inline-block;
  min-width: $button_minwidth;
  margin: 0;
  padding: 1em 1em;
  border: $style_button_border;
  border-radius: $style_button_border_radius;
  line-height: 1;
  background: $color_button_bg;
  color: $color_button_text;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  min-width: 180px;
  @media(max-width: 767px){
    min-width: 35%;
    margin-bottom: 10px;
  }

  &:hover {
    background: $color_button_bg_hover;
    color: $color_button_text_hover;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.item_detail,
.item_list {
  .item_list_link {
    display: block;
    position: relative;
    overflow: hidden;
    // border: 1px solid #ccc;
    img {
      position: absolute;
      top: -300%;
      bottom: -300%;
      left: -300%;
      right: -300%;
      margin: auto;
      // max-width: none;
      // max-height: 100%;
    }
  }
}
.item_list {
  .item_list_link {
    padding-top: 75%;
  }
  .item_all_title {
    @include fontsize(18);
    @media(max-width: 767px){
      @include fontsize(14);
    }
  }
  .item_all_price {
    @include fontsize(12);
    @media(max-width: 767px){
      @include fontsize(10);
    }
    span {
      @include fontsize(18);
      @media(max-width: 767px){
        @include fontsize(14);
      }
    }
  }
  &.row_inline {
    >.col {
      letter-spacing: normal;
      vertical-align: top;
      display: inline-block;
      float: none;
      margin-bottom: 5%;
    }
    .span_1 {
      width: 6.5%;
      &:nth-child(12n+1) {
        margin-left: 0;
      }
    }

    .span_2 {
      width: 15%;
      &:nth-child(6n+1) {
        margin-left: 0;
      }
    }

    .span_3 {
      width: 23.5%;
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }

    .span_4 {
      width: 32%;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }

    .span_5 {
      width: 40.5%;
      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }

    .span_6 {
      width: 49%;
      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }

    .span_7 {
        width: 57.5%
    }

    .span_8 {
        width: 66%
    }

    .span_9 {
        width: 74.5%
    }

    .span_10 {
        width: 83%
    }

    .span_11 {
        width: 91.5%
    }

    .span_12 {
        width: 100%
    }
  }
}
.item_detail {
  .item_list_link {
    padding-top: 75%;
  }
  .item_detail_title {
    @include fontsize(22);
    @media(max-width: 767px){
      @include fontsize(18);
    }
  }
  .span_12 {
    margin: 0;
  }
  .item_detail_price {
    @include fontsize(12);
    span {
      @include fontsize(22);
      @media(max-width: 767px){
        @include fontsize(18);
      }
    }
  }
  form {
    select {
      display: block;
      margin-top: .5em;
      @include fontsize(16);
    }
  }
}
table#cart,
table#maincart,
table#minicart {
  max-width: 100%;
  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }
  td,
  th {
    border: 1px solid $color_table_border;
  }
  th,
  th.thcount,
  th.thitem {
    background: $color_table_th_bg;
    color: $color_table_th_text;
  }
  td {
    background: $color_table_td_bg;
  }
}

#login,
#submit,
#submit_entry,
#newentry,
#maincart,
#maincart_ajax,
#loginbutton,
.widget_wrap>form {
  margin-bottom: 1em!important;
  p {
    text-align: center;
  }
  input[type="submit"] {
    @include olive_button();
  }
}
.widget_wrap>form {
  input[type="submit"] {
    display: block;
    margin-top: 5px;
  }
}

#sidecatlist {
  ul {
    @include listclear();
  }
  li {
    padding: 0.5em 0.7em;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: 0;
    }
    a {
      display: block;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// カルーセル
@charset "UTF-8";

.recommend {
  padding: 5% 0;
  margin-bottom: 0;
  /* slick */
  .slick-slider {
    overflow: hidden;
  }

  .slick-slide {
    margin: 0 0.2%;
  }

  .slick-slide a:focus {
    outline: 0;
  }

  .slick-next,
  .slick-prev {
    z-index: 10;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 10%;
      margin: auto;
      width: 20px;
      height: 20px;
      z-index: 10;
      border-top: 3px solid #1a1a1a;
      border-right: 3px solid #1a1a1a;
      transition: 0.2s;
    }
  }

  .slick-prev {
    left: 40px;

    &:before {
      left: 5%;
      transform: translate(0, -50%) rotate(-135deg);
    }
  }

  .slick-next {
    right: 40px;

    &:before {
      right: 5%;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  // figure {
  //   img {
  //     max-width: 100%;
  //     width: 100%;
  //   }
  // }

  .more {
    display: block;
    margin: 0 auto;
    text-align: center;

    a {
      display: block;
      margin: 0 auto;
      max-width: 235px;
      width: 100%;
    }
  }
  .item_photo {
    a {
      position: relative;
      display: block;
      padding-top: ($carousel01_height / $carousel01_width)*100 + %;
      overflow: hidden;
      img {
        max-width: none;
        width: auto;
        position: absolute;
        top: -300%;
        left: -300%;
        right: -300%;
        bottom: -300%;
        margin: auto;
        height: 100%;
      }
    }
  }
}
