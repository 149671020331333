@charset "UTF-8";
/* version */
@import url(vendor/common.css);
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: #fff;
  font-family: Arial, Roboto, "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  font-size: 1.4em;
  line-height: 1.4;
  color: #1a1a1a;
  height: auto !important;
  overflow: hidden;
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;
}

figure figcaption {
  margin-top: 0.5em;
  text-align: left;
}

a {
  color: #1a1a1a;
  text-decoration: underline;
}

a:hover {
  color: #1a1a1a;
  text-decoration: none;
}

.highlight a {
  color: #1a1a1a;
}

.highlight a:hover {
  color: #1a1a1a;
}

img {
  vertical-align: middle;
}

img.circle {
  border-radius: 50%;
}

p {
  margin: 0.5em 0 1em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;
}

ul:last-child, ol:last-child {
  margin-bottom: 0;
}

ul ul, ul ol, ol ul, ol ol {
  margin: 0.5em 0;
  padding-left: 2em;
}

ul ul:last-child, ul ol:last-child, ol ul:last-child, ol ol:last-child {
  margin-bottom: 0.5em;
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-unstyled ul, ul.list-unstyled ol {
  padding-left: 2em;
}

ul.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -7px;
  font-size: 0px;
  font-size: 0rem;
  margin-bottom: 14px;
}

ul.list-inline > li {
  display: inline;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

dl {
  margin: 0 0 1em;
  line-height: 1.6;
}

dl:last-child {
  margin-bottom: 0;
}

dl dt {
  font-weight: bold;
}

dl dd {
  margin-left: 0;
  margin-bottom: 0.5em;
}

dl dd:last-child {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main section {
  margin-bottom: 10%;
}

main section.highlight:last-child {
  margin-bottom: 0;
  padding-bottom: 10%;
}

.sp-mask {
  transition: none !important;
}

.highlight {
  padding: 4% 0;
  background: rgba(212, 0, 0, 0.05);
  color: #1a1a1a;
}

#swipebox-overlay {
  z-index: 10000002 !important;
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
  dl.dl-horizontal {
    overflow: hidden;
  }
  dl.dl-horizontal dt {
    float: left;
    clear: left;
    margin-right: 1em;
  }
  dl.dl-horizontal dd {
    float: left;
  }
  main section {
    margin-bottom: 5%;
  }
  main section:last-child {
    margin-bottom: 7%;
  }
  main section.highlight:last-child {
    margin-bottom: 0;
    padding-bottom: 7%;
  }
}

@media (max-width: 767px) {
  .tile > * {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .gutters .row.row_inline {
    letter-spacing: -.40em;
  }
  .gutters .row.row_inline > .col {
    letter-spacing: normal;
    vertical-align: top;
    display: inline-block;
    float: none;
    margin-bottom: 5%;
  }
  .gutters .row.row_inline .span_1:nth-child(12n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_2:nth-child(6n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_3:nth-child(4n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_4:nth-child(3n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_5:nth-child(2n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_6:nth-child(2n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_7 {
    width: 57.5%;
  }
  .gutters .row.row_inline .span_8 {
    width: 66%;
  }
  .gutters .row.row_inline .span_9 {
    width: 74.5%;
  }
  .gutters .row.row_inline .span_10 {
    width: 83%;
  }
  .gutters .row.row_inline .span_11 {
    width: 91.5%;
  }
  .gutters .row.row_inline .span_12 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .tile > * {
    height: auto !important;
  }
}

main .column_main,
main .column_sub {
  overflow: hidden;
  min-height: 100px;
}

main .column_main .widget_header,
main .column_sub .widget_header {
  font-size: 18px;
  font-size: 1.8rem;
  padding: .5em;
  margin-bottom: 0;
}

main .column_main .widget_header + *:not(.calendar_wrap),
main .column_sub .widget_header + *:not(.calendar_wrap) {
  padding: 0;
  margin: 0 auto 1em;
}

main .column_main .widget_header + .epress_search,
main .column_main .widget_header + .textwidget,
main .column_sub .widget_header + .epress_search,
main .column_sub .widget_header + .textwidget {
  padding: 10px 0;
}

main .column_main .widget_wrap .menu,
main .column_main .widget_wrap > ul,
main .column_sub .widget_wrap .menu,
main .column_sub .widget_wrap > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main .column_main .widget_wrap .menu ul,
main .column_main .widget_wrap > ul ul,
main .column_sub .widget_wrap .menu ul,
main .column_sub .widget_wrap > ul ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main .column_main .widget_wrap .menu li,
main .column_main .widget_wrap > ul li,
main .column_sub .widget_wrap .menu li,
main .column_sub .widget_wrap > ul li {
  padding: .5em .7em;
  border-bottom: 1px solid #ccc;
}

main .column_main .widget_wrap .menu li:last-child,
main .column_main .widget_wrap > ul li:last-child,
main .column_sub .widget_wrap .menu li:last-child,
main .column_sub .widget_wrap > ul li:last-child {
  border-bottom: 0;
}

main .column_main .widget_wrap .menu li a,
main .column_main .widget_wrap > ul li a,
main .column_sub .widget_wrap .menu li a,
main .column_sub .widget_wrap > ul li a {
  display: block;
  text-decoration: none;
}

main .column_main .widget_wrap .menu li a:hover,
main .column_main .widget_wrap > ul li a:hover,
main .column_sub .widget_wrap .menu li a:hover,
main .column_sub .widget_wrap > ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .gutters .span_9.column_main,
  .gutters .span_3.column_sub {
    float: none;
    width: 100%;
    margin-left: 0%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub .sidebar {
    letter-spacing: -.40em;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-left: 2%;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap:nth-child(2n+1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 10px;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_list:not(.reverse) .col.span_12 + .span_4,
  .article_detail:not(.reverse) .col.span_12 + .span_4 {
    margin-left: 0;
  }
  .article_list.reverse .col.span_12,
  .article_detail.reverse .col.span_12 {
    margin-left: 0;
  }
}

.sidebar {
  visibility: hidden;
}

.sidebar > .widget_wrap {
  visibility: visible;
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.sitemap {
  padding: 0;
  margin: 0;
}

.sitemap li {
  list-style: none;
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #ccc;
}

.sitemap a {
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: none;
}

.sitemap a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .sitemap a {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.parallax_h1 {
  height: 250px;
  position: relative;
}

.parallax_h1:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.3);
}

.parallax_h1 h1 {
  color: #fff;
}

.parallax_h1 h1:before, .parallax_h1 h1:after {
  background: #fff;
}

@media (min-width: 768px) {
  .parallax_h1 {
    height: 450px;
  }
}

.map_wide iframe,
.map_wide #map_canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_wide img {
  max-width: none;
}

.slick-track {
  margin: 0 auto;
}

header {
  position: relative;
  z-index: 100;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

header section {
  margin: 0;
  width: 100%;
}

header .row {
  margin: 0 3%;
}

@media (max-width: 768px) {
  header .row {
    margin-bottom: 0 !important;
  }
}

header .primary_header {
  color: #1a1a1a;
  background: url(/wp-content/uploads/hdr_line.jpg) repeat-x top;
}

header .title {
  max-width: 40%;
  margin: 0;
  width: 20%;
  padding: 15px 1% 10px 1%;
  line-height: 1;
  text-align: left;
  font-size: 10px;
  font-size: 1rem;
  max-width: none;
  width: 100%;
}

@media (max-width: 600px) {
  header .title {
    width: 90%;
  }
}

header .title object {
  max-width: 240px;
}

header .title img {
  width: auto;
  max-width: 100%;
  vertical-align: middle;
}

header .title a {
  display: inline-block;
}

header .title object {
  pointer-events: none;
  width: 217px;
  height: 56px;
}

@media (max-width: 600px) {
  header .title object {
    width: 200px;
    height: 51.6129px;
    min-height: 45px;
  }
}

header .global_nav:not(:last-child) {
  display: none;
}

header .global_nav > ul {
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #333;
}

@media (min-width: 768px) {
  header .global_nav > ul {
    left: auto;
    width: 50%;
    max-width: 400px;
  }
}

header .global_nav > ul > li {
  border-bottom: 1px solid #333;
}

header .global_nav > ul > li a {
  display: block;
  padding: 1.5em 1em;
  text-decoration: none;
  color: #fff;
  background: rgba(212, 0, 0, 0.9);
}

header .global_nav > ul > li a:hover {
  color: #fff;
}

header .global_nav > ul > li a:active {
  background: #d40000;
  color: #fff;
}

header .global_nav > ul > li > a {
  background: rgba(212, 0, 0, 0.9);
}

header .global_nav .menu_icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

header .global_nav .menu_icon a {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transition: transform .3s;
  overflow: hidden;
}

header .global_nav .menu_icon a:before, header .global_nav .menu_icon a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background: #d40000;
  transition: transform .5s;
}

header .global_nav .menu_icon a:before {
  top: 0;
}

header .global_nav .menu_icon a:after {
  bottom: 0;
}

header .global_nav .menu_icon a span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background: #d40000;
  transition: transform .5s;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

header .global_nav .menu_icon a.close:not(.fix) {
  background: none;
}

header .global_nav .menu_icon a.close:not(.fix):before {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  background: #d40000;
}

header .global_nav .menu_icon a.close:not(.fix):after {
  bottom: 50%;
  transform: translate(0, 50%) rotate(-45deg);
  background: #d40000;
}

header .global_nav .menu_icon a.close:not(.fix) span {
  transform: translate(100%, -50%);
}

header .global_nav > ul {
  transform-origin: center top;
  visibility: hidden;
  transition: transform .3s;
  transform: rotateX(90deg);
}

header.menu_open .global_nav > ul {
  visibility: visible;
  transform: rotateX(0deg);
}

body header {
  transition: top .5s;
}

body.scroll.down header {
  position: absolute;
  top: 0;
  left: 0;
}

body.scroll.down header.high {
  position: fixed;
}

body.scroll header {
  z-index: 0;
}

body.scroll header.hidden {
  top: -100%;
}

header.menu_open,
body.up header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

#google_translate_element, .translate_gt {
  position: absolute;
  top: 50%;
  right: 15%;
  margin-top: -12px;
}

.translate_gt {
  margin-top: -14px;
}

@media (min-width: 768px) {
  header .container .row {
    display: table;
    width: 100%;
    margin-bottom: 0;
  }
  header .row {
    margin: 0 3%;
  }
  header .title, header .global_nav {
    margin: 0;
    vertical-align: middle;
  }
  header .title {
    max-width: none;
    width: 217px;
    max-width: none;
    padding: 30px 0;
    width: 100%;
    text-align: left;
  }
  header .title object {
    max-width: 100%;
  }
  header .title img {
    width: auto;
  }
  header .global_nav {
    text-align: right;
    width: calc(94vw - 217px);
    padding: 0;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }
  header .global_nav:not(:last-child) {
    display: table-cell;
  }
  header .global_nav > ul {
    position: static;
    display: inline-block;
    width: auto;
    border: none;
    background: none;
    visibility: visible;
    font-size: 0px;
    font-size: 0rem;
    transform: none;
    text-align: left;
    overflow: visible;
    max-width: none;
  }
  header .global_nav > ul > li {
    display: inline-block;
    vertical-align: middle;
    border: none;
    line-height: 1.5;
    padding: .3em 1.2em;
    border-left: 1px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  header .global_nav > ul > li > a {
    display: inline;
    padding: 0;
    color: #fff;
    text-decoration: none;
    background: transparent;
  }
  header .global_nav > ul > li > a:hover {
    /*border-bottom: 2px solid $color_navlink_pc_hover;*/
    color: #fff;
  }
  header .global_nav > ul > li > a:active {
    background: none;
  }
  header .global_nav > ul > li a {
    padding: .3em 0;
    transition: .3s;
  }
  header .global_nav > ul > li:last-child {
    border-right: 1px;
  }
  header .global_nav .menu_icon {
    display: none;
  }
  #google_translate_element, .translate_gt {
    position: static;
    float: right;
    margin-top: 3px;
    margin-left: 1em;
  }
  .translate_gt {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  header .global_nav > ul > li > a > br,
  header .global_nav > ul > li > a > span {
    display: none;
  }
}

.hdr_info {
  display: none;
}

@media (min-width: 768px) {
  .hdr_info {
    display: block;
    position: absolute;
    top: 35px;
    right: 3%;
    border-left: solid 4px #1a1a1a;
    padding-left: 10px;
    color: #000 !important;
    line-height: 1.6;
  }
  .hdr_info a {
    text-decoration: none;
  }
}

footer {
  position: relative;
  background: #333333;
  color: #fff;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

footer nav {
  text-align: center;
}

footer nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer nav ul li + li {
  border-top: 1px solid #666;
}

footer nav a {
  display: block;
  padding: .3em .3em;
  text-decoration: none;
}

footer nav a:hover {
  text-decoration: underline;
}

footer .copyright {
  text-align: center;
}

footer .copyright p {
  padding: 1em .3em;
}

@media (min-width: 768px) {
  footer .row:not(:last-child) {
    margin-bottom: 5px;
  }
  footer nav {
    text-align: center;
  }
  footer nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0px;
    font-size: 0rem;
    white-space: normal;
  }
  footer nav ul > li {
    display: inline;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
    font-size: 14px;
    font-size: 1.4rem;
  }
  footer nav ul li {
    line-height: 1.5;
    white-space: normal;
  }
  footer nav ul li + li {
    border: 0;
  }
  footer nav ul li:not(:first-child):before {
    content: "|";
    margin-right: .3em;
    margin-left: .3em;
  }
  footer nav a {
    display: inline;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li.tablet-break:after {
    content: "\A";
    white-space: pre;
  }
  footer nav ul li.tablet-break + li:before {
    display: none;
  }
}

/*
 * フッターパターンA(footer pattern A)
 */
@media (min-width: 768px) {
  footer .container .row .col {
    float: none;
    width: 100%;
  }
  footer .container .row nav {
    padding: 1em 0 0;
  }
  footer .container .row nav ul {
    display: inline-block;
  }
  footer .container .row nav a {
    display: inline-block;
    padding: .3em .3em;
  }
}

.slider-pro.contents {
  overflow: hidden;
  margin: 0 auto !important;
}

.slider-pro.contents .sp-caption-container {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.slider-pro.contents .sp-caption-container h3 {
  margin: 0 0 15px;
  font-size: 14px;
  font-size: 1.4rem;
}

.slider-pro.contents .sp-caption-container h3:last-child {
  margin-bottom: 0;
}

.slider-pro.contents .sp-caption-container p {
  font-size: 13px;
  font-size: 1.3rem;
}

.slider-pro.contents.sp-horizontal .sp-previous-arrow {
  left: 10px;
  margin-top: 180px;
}

.slider-pro.contents.sp-horizontal .sp-next-arrow {
  right: 10px;
  margin-top: 180px;
}

.slider-pro.contents .sp-next-arrow:after,
.slider-pro.contents .sp-next-arrow:before,
.slider-pro.contents .sp-previous-arrow:after,
.slider-pro.contents .sp-previous-arrow:before {
  background-color: #d40000;
}

@media (max-width: 767px) {
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
}

@media (min-width: 768px) {
  .slider-pro.contents .sp-caption-container {
    width: 450px;
    min-height: 40px;
    margin: 10px auto 0;
    border-right: 1px dashed #999;
    border-left: 1px dashed #999;
  }
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
  .slider-pro.contents.sp-horizontal .sp-previous-arrow {
    left: 50%;
    margin-top: 187.5px;
    margin-left: -230px;
  }
  .slider-pro.contents.sp-horizontal .sp-next-arrow {
    right: 50%;
    margin-top: 187.5px;
    margin-right: -230px;
  }
}

.slider-pro.center {
  overflow: hidden;
  width: 100%;
}

.slider-pro.center .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.center .sp-selected-button {
  background: #d40000;
}

.slider-pro.center.sp-horizontal .sp-arrows {
  left: 0;
  right: 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.slider-pro.center.sp-horizontal .sp-previous-arrow {
  left: 10px;
}

.slider-pro.center.sp-horizontal .sp-next-arrow {
  right: 10px;
}

.slider-pro.center .sp-previous-arrow,
.slider-pro.center .sp-next-arrow {
  width: 30px;
  height: 30px;
  background-color: rgba(212, 0, 0, 0.7);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px;
}

.slider-pro.center .sp-previous-arrow {
  background-image: url(/wp-content/uploads/arrow_left_wh.png);
}

.slider-pro.center .sp-next-arrow {
  background-image: url(/wp-content/uploads/arrow_right_wh.png);
}

.slider-pro.center .sp-next-arrow:after,
.slider-pro.center .sp-next-arrow:before,
.slider-pro.center .sp-previous-arrow:after,
.slider-pro.center .sp-previous-arrow:before {
  display: none;
}

.slider-pro.center .sp-layer {
  line-height: 1.2;
  white-space: normal !important;
  font-size: 20px;
  font-size: 2rem;
  color: #1a1a1a;
}

.slider-pro.center .sp-layer small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .slider-pro.center .sp-layer {
    font-size: 22px;
    font-size: 2.2rem;
  }
  .slider-pro.center .sp-padding {
    padding: 35px;
  }
}

@media (min-width: 1100px) {
  .slider-pro.center.sp-horizontal .sp-previous-arrow {
    left: -15px;
  }
  .slider-pro.center.sp-horizontal .sp-next-arrow {
    right: -15px;
  }
}

.slider-pro.wide {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 10px;
}

.slider-pro.wide .sp-buttons {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: auto;
  padding-top: 0;
  text-align: right;
}

.slider-pro.wide .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.wide .sp-selected-button {
  background: #d40000;
}

.slider-pro.wide .sp-previous-arrow,
.slider-pro.wide .sp-next-arrow {
  z-index: 2;
}

.slider-pro.wide .sp-next-arrow:after,
.slider-pro.wide .sp-next-arrow:before,
.slider-pro.wide .sp-previous-arrow:after,
.slider-pro.wide .sp-previous-arrow:before {
  color: #fff;
}

.slider-pro.wide .hps-slider-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-pro.wide .hps-slider-mask.stripe {
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url(/wp-content/uploads/mask_stripe.png);
}

.slider-pro.wide .hps-slider-copy {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 50px;
  color: #1a1a1a;
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
}

.slider-pro.wide .hps-slider-copy .lead {
  margin: 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  color: #1a1a1a;
}

.slider-pro.wide.disable-arrows .hps-slider-copy {
  padding: 0 10px;
}

.slider-pro.wide .hps-slider-scroll {
  display: none;
}

@media (max-width: 767px) {
  .slider-pro.wide.sp-disable-arrows .hps-slider-copy {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .slider-pro.wide .sp-buttons {
    bottom: 20px;
    right: 25px;
  }
  .slider-pro.wide .sp-button {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
  .slider-pro.wide .hps-slider-copy {
    text-align: center;
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.8;
  }
  .slider-pro.wide .hps-slider-copy .lead {
    font-size: 46px;
    font-size: 4.6rem;
  }
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .slider-pro.wide .hps-slider-scroll a {
    display: inline-block;
    width: 100px;
    padding-bottom: 45px;
    background: url(/wp-content/uploads/arrow_down_white.png) center bottom no-repeat;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    text-decoration: none;
  }
  .slider-pro.wide .hps-slider-scroll.text-black a {
    background: url(/wp-content/uploads/arrow_down_black.png) center bottom no-repeat;
    color: #000;
  }
}

@media (min-width: 1024px) {
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    bottom: 45px;
  }
}

@media (max-width: 767px) {
  .bf-af .row:not(:last-child) {
    margin-bottom: 10%;
  }
  .bf-af .before {
    padding-bottom: 16%;
    background: url(/wp-content/uploads/arrow_down.svg) center bottom no-repeat;
    background-size: 20%;
  }
  .bf-af .after:not(:last-child) {
    margin-bottom: 10%;
  }
}

@media (min-width: 768px) {
  .bf-af {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .bf-af .before, .bf-af .after {
    display: table-cell;
    vertical-align: top;
  }
  .bf-af .before span {
    display: block;
    position: relative;
  }
  .bf-af .before span:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    background: url(/wp-content/uploads/arrow_right.svg) center center no-repeat;
    background-size: 100%;
  }
  .bf-af.col2 .before {
    width: 55%;
    padding-right: 10%;
  }
  .bf-af.col2 .after {
    width: 45%;
  }
  .bf-af.col2 .before span:after {
    width: 10%;
    right: -16%;
  }
  .bf-af.col4 .before:nth-child(1) {
    width: 24%;
    padding-left: 0;
    padding-right: 2%;
  }
  .bf-af.col4 .after:nth-child(2) {
    width: 26%;
    padding-left: 3%;
    padding-right: 1%;
  }
  .bf-af.col4 .before:nth-child(3) {
    width: 26%;
    padding-left: 1%;
    padding-right: 3%;
  }
  .bf-af.col4 .after:nth-child(4) {
    width: 24%;
    padding-left: 2%;
    padding-right: 0;
  }
  .bf-af.col4 .before span:after {
    width: 13%;
    right: -18%;
  }
}

main section .bread_wrap {
  background: transparent;
  color: #d40000;
  margin-bottom: 2%;
}

.bread {
  overflow: hidden;
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
}

.bread ul {
  padding: 1em;
  margin: 0 auto;
  float: none;
}

.bread ul li {
  list-style: none;
  position: relative;
  float: left;
  padding-right: 2em;
  margin-right: .5em;
}

.bread ul li a {
  text-decoration: none;
  color: #1a1a1a;
}

.bread ul li a:hover {
  color: #1a1a1a;
  text-decoration: none;
}

.bread ul li:after {
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread ul li:last-child:after {
  content: none;
}

.button {
  display: inline-block;
  min-width: 200px;
  margin: 0;
  padding: 1em 1em;
  border: 1px solid;
  border-radius: 4px;
  line-height: 1;
  background: transparent;
  color: #d40000;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
}

.button:hover {
  background: #d40000;
  color: #fff;
}

.highlight .button {
  color: #d40000;
}

.highlight .button:hover {
  color: #fff;
}

.button.block {
  display: block;
  min-width: 0;
}

.button.inverse {
  border: 0;
  border-radius: 4px;
  background: #000;
  color: #fff;
}

.button.inverse:hover {
  background: #1a1a1a;
  color: #fff;
}

.button.cancel {
  background: #888;
  color: #fff;
}

.button.cancel:hover {
  background: #6f6f6f;
}

.button.large {
  padding: 1em 2em;
  font-size: 16px;
  font-size: 1.6rem;
}

.button + .button {
  margin-left: 0.5em;
}

.button span {
  line-height: 1;
  padding-left: 20px;
  background: url(/wp-content/uploads/btn_icon.png) left center no-repeat;
  background-size: auto 14px;
}

.feature {
  color: #fff;
}

.feature h3 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-size: 2.2rem;
}

.feature img {
  width: 100%;
}

.feature .col {
  display: table;
  table-layout: fixed;
}

.feature .col:not(:last-child) {
  margin-bottom: 0;
}

.feature .col:nth-child(1) {
  background: #49afe6;
}

.feature .col:nth-child(2) {
  background: #8ed95d;
}

.feature .col:nth-child(3) {
  background: #f6aa48;
}

.feature .col:nth-child(4) {
  background: #f98282;
}

.feature .col .textbox {
  display: table-cell;
  width: 70%;
  padding: 1em 3%;
  vertical-align: middle;
}

.feature .col figure {
  display: table-cell;
  width: 30%;
  vertical-align: middle;
}

.feature .col figure:first-child img {
  text-align: left;
}

.feature .col figure:last-child img {
  text-align: right;
}

@media (min-width: 768px) {
  .feature h3 {
    margin: 10px 0 15px;
    font-size: 26px;
    font-size: 2.6rem;
  }
  .feature .col {
    display: block;
    table-layout: auto;
  }
  .feature .col .textbox {
    display: block;
    width: auto;
    height: 250px;
    padding: 10px;
    font-size: 13px;
    font-size: 1.3rem;
  }
  .feature .col figure {
    display: inline;
    width: auto;
    vertical-align: middle;
  }
}

.grid {
  display: block;
  margin: 0;
}

.grid.row, .grid .row, .grid.col, .grid .col {
  margin: 0;
}

.has-child-grid .grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
}

.has-child-grid .grid .col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
}

.has-child-grid .grid .col.span_12 {
  padding: 20px 0;
}

.grid .grid-arrow {
  position: relative;
  overflow: hidden;
}

.grid .grid-arrow:after {
  content: '';
  display: block;
  position: absolute;
  right: -15px;
  bottom: -15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  transform: rotate(45deg);
  transition: .3s;
}

.grid p {
  margin: 0;
  padding: 0.2em 0;
}

.grid a {
  color: #fff;
  text-decoration: none;
  transition: .3s;
}

.grid a > [style^="background-image"], .grid a img {
  transition: .3s;
}

.grid a:hover > [style^="background-image"], .grid a:hover img {
  opacity: 0.6;
}

.grid .grid-bg1 {
  background-color: #e74c3c;
}

.grid .grid-bg1:hover {
  background-color: #eb6d60;
}

.grid .grid-bg1:hover .grid-arrow:after {
  background-color: #df2e1b;
}

.grid .grid-bg2 {
  background-color: #3498db;
}

.grid .grid-bg2:hover {
  background-color: #57aae1;
}

.grid .grid-bg2:hover .grid-arrow:after {
  background-color: #2383c4;
}

.grid .grid-bg3 {
  background-color: #f1c40f;
}

.grid .grid-bg3:hover {
  background-color: #f3cd36;
}

.grid .grid-bg3:hover .grid-arrow:after {
  background-color: #cba50c;
}

.grid .grid-bg4 {
  background-color: #1abc9c;
}

.grid .grid-bg4:hover {
  background-color: #1fe0ba;
}

.grid .grid-bg4:hover .grid-arrow:after {
  background-color: #15987e;
}

.grid .grid-bg5 {
  background-color: #9b59b6;
}

.grid .grid-bg5:hover {
  background-color: #ac75c2;
}

.grid .grid-bg5:hover .grid-arrow:after {
  background-color: #8646a0;
}

.grid .grid-bg6 {
  background-color: #34495e;
}

.grid .grid-bg6:hover {
  background-color: #435d78;
}

.grid .grid-bg6:hover .grid-arrow:after {
  background-color: #253544;
}

.grid .grid-bg7 {
  background-color: #2ecc71;
}

.grid .grid-bg7:hover {
  background-color: #4cd787;
}

.grid .grid-bg7:hover .grid-arrow:after {
  background-color: #26ab5f;
}

.ie .grid .grid-arrow:after {
  display: none;
}

@media (min-width: 768px) {
  .grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
  }
  .grid .col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .grid .col.span_12 {
    padding: 0;
  }
  .grid .col.has-child-grid > * {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .grid p {
    margin: 0;
    padding: 0.2em 0;
  }
}

.guide figure {
  margin-bottom: 1em;
}

.guide figure img {
  width: auto;
  max-width: 100%;
}

.guide .message .contents_link {
  text-align: center;
}

@media (min-width: 768px) {
  .guide {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: -1em;
  }
  .guide figure {
    flex: 1;
    margin-bottom: 0;
    min-width: 0;
  }
  .guide .message {
    flex: 3;
    padding-left: 2%;
    padding-bottom: 45px;
  }
  .guide .message.text-only {
    padding-bottom: 0;
  }
  .guide .message h2 {
    margin-bottom: 1em;
  }
  .guide .message .contents_link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .guide.reverse {
    flex-direction: row-reverse;
  }
  .guide.reverse .message {
    padding-left: 0;
    padding-right: 2%;
  }
  .ie .guide {
    display: table;
  }
  .ie .guide figure {
    display: table-cell;
    width: 23.5%;
    vertical-align: middle;
  }
  .ie .guide .message {
    display: table-cell;
    width: 74.5%;
    padding-left: 2%;
    vertical-align: middle;
  }
  .ie .guide.reverse .message {
    padding-left: 2%;
    padding-right: 0;
  }
}

/* お問い合わせフォーム(form liên lạc) */
.inquiry fieldset {
  margin: 0;
  padding: 20px 10px;
  border: 0;
  background: rgba(212, 0, 0, 0.05);
}

.inquiry fieldset .row {
  margin: 0;
}

.inquiry dl {
  margin: 0;
  padding: 0;
}

.inquiry dt {
  font-weight: normal;
}

.inquiry dt.col:not(:last-child) {
  margin-bottom: 1em;
}

.inquiry dd {
  margin: 0;
  padding: 0;
}

.inquiry dd:last-child {
  padding-bottom: 0;
}

.inquiry dd .confirm {
  padding: 0 0 1em 1em;
  border-bottom: 1px dashed #ccc;
}

.inquiry input[type="text"],
.inquiry input[type="url"],
.inquiry input[type="email"],
.inquiry input[type="tel"],
.inquiry input[type="date"],
.inquiry input[type="number"],
.inquiry select,
.inquiry textarea {
  width: 100%;
  padding: 5px;
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
}

.inquiry input[type="number"] {
  width: 65px;
  text-align: center;
}

.inquiry input.p-postal-code {
  width: 100px;
}

.inquiry input.p-region {
  width: 100px;
}

.inquiry input.p-locality {
  width: 150px;
}

.inquiry input.p-street-address {
  width: 100%;
}

.inquiry input.p-extended-address {
  width: 100%;
}

.inquiry .submit {
  margin: 1em 0;
  text-align: center;
}

.inquiry .required {
  color: #c00;
}

.inquiry label {
  display: block;
  margin-bottom: 0.5em;
}

@media (min-width: 768px) {
  .inquiry {
    max-width: 780px;
    margin-right: auto;
    margin-left: auto;
  }
  .inquiry fieldset {
    margin: 0;
    padding: 60px;
    border: 0;
  }
  .inquiry dt {
    clear: both;
    padding: 6px 0;
  }
  .inquiry dd {
    padding-bottom: 1.5em;
  }
  .inquiry dd .confirm {
    min-height: 1.5em;
    padding: 6px 0 0;
    border: 0;
    line-height: 1.4;
  }
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="text"],
  .inquiry select {
    width: 60%;
  }
  .inquiry input[type="number"] {
    width: 65px;
  }
  .inquiry input.p-postal-code {
    width: 100px;
  }
  .inquiry input.p-region {
    width: 100px;
  }
  .inquiry input.p-locality {
    width: 150px;
  }
  .inquiry input.p-street-address {
    width: 100%;
  }
  .inquiry input.p-extended-address {
    width: 100%;
  }
  .inquiry textarea {
    width: 100%;
  }
  .inquiry .submit {
    margin: 60px 0 0;
  }
  .inquiry label {
    display: inline-block;
    margin-top: 6px;
    margin-bottom: 0;
    margin-right: 0.8em;
  }
  .gutters .inquiry dt.col {
    margin-left: 0;
  }
}

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.movie.ar16to9 {
  padding-top: 56.25%;
}

.movie.ar4to3 {
  padding-top: 75%;
}

.news .news_bg {
  background: rgba(212, 0, 0, 0.05);
}

.news.typeC .news_bg {
  padding: 1em;
}

.news h2 {
  color: #1a1a1a;
}

.news dl {
  margin: 0;
}

.news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  /*border-bottom: $style_news_border;*/
}

.news dl dd a {
  text-decoration: none;
}

.news dl dd a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .news.typeA > div {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  .news.typeA > div .heading {
    display: table-cell;
    width: 23.5%;
    vertical-align: middle;
  }
  .news.typeA h2 {
    margin: 0;
    padding: 0;
  }
  .news.typeA h2:after {
    display: none;
  }
  .news.typeA .content {
    display: table-cell;
    overflow: hidden;
    width: 74.5%;
    padding-left: 2%;
    vertical-align: middle;
  }
  .news.typeC .news_bg {
    height: 540px;
    padding: 20px;
  }
  .news.typeC dl {
    max-height: 500px;
  }
  .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .news dl dt {
    width: 100px;
    clear: left;
    float: left;
    margin: 0;
    padding: 1em 0;
  }
  .news dl dd {
    margin: 0;
    padding: 1em 0 1em 0;
  }
  .news dl dt + dd {
    padding-left: 120px;
  }
}

.parallax {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.parallax.ios {
  background-attachment: scroll;
}

.parallax .parallax_contents {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.parallax .parallax_contents h1, .parallax .parallax_contents .h1 {
  margin: 0;
  padding: 1em 0;
}

.parallax_1 {
  height: 900px;
  background-image: url(/wp-content/uploads/parallax_bg.jpg);
}

@media (min-width: 768px) {
  .parallax_1 {
    height: 700px;
  }
}

.qa h3.question {
  font-size: 16px;
  font-size: 1.6rem;
  border: none;
}

.qa .question {
  position: relative;
  margin-bottom: 1em;
  padding: 1em 50px 1em 1em;
  background-color: rgba(212, 0, 0, 0.05);
  font-weight: normal;
}

.qa .question:after {
  content: none;
}

.qa .question:before {
  content: none;
}

.qa .question:hover {
  background: rgba(197, 0, 0, 0.05);
}

.qa .question.open:after {
  transform: rotate(180deg);
}

.qa .answer:not(:last-child) {
  margin-bottom: 2em;
}

.qa.qa-open .question {
  padding-right: 1em;
  cursor: default;
}

.qa.qa-open .question:hover {
  background-color: rgba(212, 0, 0, 0.05);
}

.qa.qa-open .question:after {
  display: none;
}

.qa.qa-open .answer {
  display: block;
}

.highlight .qa .question {
  background: rgba(238, 0, 0, 0.05);
}

.highlight .qa .question:hover {
  background: rgba(222, 0, 0, 0.05);
}

.highlight .qa.qa-open .question:hover {
  background: rgba(238, 0, 0, 0.05);
}

.shopinfo figure {
  max-width: 80%;
  margin: 0 auto;
}

.shopinfo address {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-style: normal;
}

.shopinfo dl {
  margin: 2em 0;
  line-height: 1.4;
}

.shopinfo dl:first-child {
  margin-top: 0;
}

.shopinfo dl dt {
  margin: 0 0 0.2em 0;
  font-weight: normal;
}

.shopinfo dl dt:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 0.3em;
  vertical-align: middle;
  background: #d40000;
}

.shopinfo dl dd {
  margin: 0 0 0.5em 1em;
  padding: 0;
}

.shopinfo .map_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.shopinfo .map_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopinfo .map_container + p {
  margin-top: 2em;
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.scroll .googlemap {
  pointer-events: none;
}

@media (min-width: 768px) {
  .shopinfo figure {
    max-width: 100%;
  }
  .shopinfo address {
    margin-bottom: 15px;
    text-align: left;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1;
  }
  .shopinfo dl.dl-horizontal {
    margin: 10px 0;
  }
  .shopinfo dl.dl-horizontal dd {
    margin-bottom: 0.3em;
  }
  .shopinfo .span12 .map_container {
    padding-top: 50%;
  }
  .map_wide {
    padding-top: 420px;
  }
}

.sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0px;
  font-size: 0rem;
  margin: auto;
}

.sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

.sns_icons li {
  display: inline-block;
}

.sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.sns_icons a.icon_facebook {
  background-image: url(/wp-content/uploads/icon_facebook.png);
}

.sns_icons a.icon_twitter {
  background-image: url(/wp-content/uploads/icon_twitter.png);
}

.sns_icons a.icon_line {
  background-image: url(/wp-content/uploads/icon_line.png);
}

.sns_icons a.icon_google {
  background-image: url(/wp-content/uploads/icon_google.png);
}

.sns_icons a.icon_instagram {
  background-image: url(/wp-content/uploads/icon_instagram.png);
}

footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .sns_icons {
    margin: 0;
  }
  .sns_icons.center {
    margin: auto;
  }
  .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .sns_icons a {
    width: 68px;
    height: 68px;
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col:not(:last-child) {
    margin-bottom: 1em;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure {
    overflow: hidden;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure img {
    float: left;
    width: 32%;
    margin-bottom: 10px;
    vertical-align: middle;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure figcaption {
    float: right;
    width: 66%;
    margin-top: 0;
    vertical-align: middle;
  }
  .sp_image_cols.sp_col2 .col {
    width: 49%;
  }
  .sp_image_cols.sp_col2 .col:nth-child(odd) {
    clear: both;
    float: left;
  }
  .sp_image_cols.sp_col2 .col:nth-child(even) {
    float: right;
  }
  .sp_image_cols.sp_col3 .col {
    float: left;
    width: 32%;
  }
  .sp_image_cols.sp_col3 .col:nth-child(3n+1) {
    clear: both;
    margin-right: 2%;
  }
  .sp_image_cols.sp_col3 .col:nth-child(3n) {
    float: right;
  }
  .sp_image_left {
    overflow: hidden;
  }
  .sp_image_left .col:first-child {
    display: inline-block;
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 0.5em;
  }
  .sp_image_left .col:last-child {
    float: none;
  }
  .sp_image_right {
    overflow: hidden;
  }
  .sp_image_right .col:first-child {
    display: inline-block;
    float: right;
    width: 32%;
    margin-left: 2%;
    margin-bottom: 0.5em;
  }
  .sp_image_right .col:last-child {
    float: none;
  }
}

.step .row,
.step-h .col {
  position: relative;
  padding: 1em;
  background: rgba(212, 0, 0, 0.05);
}

.step .row:not(:last-child):after,
.step-h .col:not(:last-child):after,
.step-h .row.continue .col:last-child:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  background: url(/wp-content/uploads/arrow_down.svg) center center no-repeat;
  background-size: auto 100%;
}

@media (max-width: 767px) {
  .step .row:not(:last-child),
  .step-h .col:not(:last-child),
  .step-h .row.continue .col:last-child {
    margin-bottom: 18%;
  }
  .step .row:not(:last-child):after,
  .step-h .col:not(:last-child):after,
  .step-h .row.continue .col:last-child:after {
    padding-top: 20%;
  }
  .step-h .row.continue {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .step .row {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 2em;
  }
  .step .row .col {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  .step .row .col:not(:first-child) {
    padding-left: 2%;
  }
  .step .row:not(:last-child) {
    margin-bottom: 80px;
  }
  .step .row:not(:last-child):after {
    top: 100%;
    padding-top: 60px;
    margin: 10px 0;
  }
  .step h3:last-child {
    margin-bottom: 0;
    font-size: 16px;
    font-size: 1.6rem;
    border: none;
  }
  .step h3:last-child:before, .step h3:last-child:after {
    content: none;
  }
  .step-h.gutters .col {
    margin: 0;
  }
  .step-h .col {
    padding: 0;
    background: none;
  }
  .step-h .col:not(:last-child) {
    position: relative;
    margin-right: 4%;
  }
  .step-h .col:not(:last-child):after {
    display: none;
  }
  .step-h .col:not(:last-child) figure span {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .step-h .col:not(:last-child) figure span:after {
    content: '';
    display: block;
    width: 28px;
    height: 100%;
    position: absolute;
    right: -30px;
    top: 0;
    background: url(/wp-content/uploads/arrow_right.svg) center center no-repeat;
    background-size: contain;
  }
  .step-h .span_3 {
    width: 22%;
  }
  .step-h .span_4 {
    width: 30.66666666%;
  }
  .step-h .span_6 {
    width: 48%;
  }
}

@media (min-width: 1024px) {
  .step-h .col:not(:last-child) figure span:after {
    right: -34px;
  }
}

table {
  margin: 0 auto;
}

table th, table td {
  padding: 0.5em 1em;
}

table th {
  text-align: center;
  font-weight: normal;
}

.table-default {
  width: 100%;
}

.table-default caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-default th, .table-default td {
  border: 1px solid #1a1a1a;
}

.table-default th {
  background: rgba(26, 26, 26, 0.7);
  color: #fff;
}

.table-default td {
  background: transparent;
}

.table-menu {
  width: 100%;
}

.table-menu caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-menu th, .table-menu td {
  padding: 0.8em 1em;
  border-top: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
}

.table-menu tbody th {
  text-align: left;
}

.table-menu td:last-child {
  text-align: right;
  white-space: nowrap;
}

.table-schedule {
  width: 100%;
}

.table-schedule caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-schedule th, .table-schedule td {
  border: 1px solid #1a1a1a;
  padding: 5px;
  text-align: center;
}

.table-schedule thead th {
  background: rgba(26, 26, 26, 0.7);
  color: #fff;
}

.table-schedule tbody th {
  background: transparent;
}

.table-schedule td {
  background: transparent;
}

@media (max-width: 767px) {
  .responsive-stack {
    display: block;
  }
  .responsive-stack thead {
    display: none;
  }
  .responsive-stack tbody, .responsive-stack tr, .responsive-stack th, .responsive-stack td {
    display: block;
  }
  .responsive-stack th, .responsive-stack td {
    width: 100% !important;
  }
  .responsive-stack tr:not(:last-child) th, .responsive-stack tr:not(:last-child) td {
    border-bottom: 0;
  }
  .responsive-stack tr:last-child :not(:last-child) {
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .responsive-list {
    display: block;
  }
  .responsive-list thead {
    display: none;
  }
  .responsive-list tbody, .responsive-list tr, .responsive-list th, .responsive-list td {
    display: block;
  }
  .responsive-list tr:not(:last-child) td {
    border-bottom: 0;
  }
  .responsive-list td {
    position: relative;
    padding-left: 40%;
    white-space: normal;
    text-align: left;
  }
  .responsive-list td:first-child {
    border-bottom: 0;
  }
  .responsive-list td:last-child {
    border-top: 0;
  }
  .responsive-list td:not(:first-child):not(:last-child) {
    border-top: 0;
    border-bottom: 0;
  }
  .responsive-list td:before {
    content: attr(data-title);
    position: absolute;
    top: 6px;
    left: 6px;
    width: 35%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: scroll;
  }
  .responsive-scroll-container .responsive-scroll-inner {
    width: 798px;
    padding: 0 15px 15px;
  }
  .responsive-scroll-container table {
    width: 768px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  table.combine tbody tr:last-child th, table.combine tbody tr:last-child td {
    border-bottom: 0;
  }
}

.wide_image img {
  width: 100%;
  vertical-align: top;
}

@media (min-width: 768px) {
  .wide_image div {
    margin: 0 auto;
  }
}

/*h1:not(.title) {
  text-align: center;
  position: relative;
  @include fontsize(36);
  font-weight: normal;
  padding-bottom: .7em;
  color: $color_h1_text;
  margin: 1em 0 .5em;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 4px;
    background: $color_h1_text;
    transition: 1s .5s;
  }
  &.wow {
    @if $wow == true {
      opacity: 0;
      transition: .5s;
      &:after {
        width: 0;
      }
      &.animated {
        opacity: 1;
        &:after {
          width: 200px;
        }
      }
    }
  }
}*/
/*h2 {
  display: table;
  table-layout: fixed;
  margin: 0 auto 1.5em;
  position: relative;
  border: 0;
  word-wrap: break-word;
  font-weight: normal;
  @include fontsize(24);
  color: $color_h2_text;
  @media ( max-width : 600px ) {
    //width: 80%;
  }
  @if $wow == true {
    opacity: 0;
    transition: .5s;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background: #000;
    @if $wow == true {
      opacity: 0;
      width: 0;
      transition: 1s .3s;
    }
    @else {
      width: 50vw;
    }
  }
  &:before {
    right: calc(100% + 15px);
  }
  &:after {
    left: calc(100% + 15px);
  }
}
@if $wow == true {
  h2 {
    &.animated {
      opacity: 1;
      &:after,
      &:before {
        opacity: 1;
        width: 50vw;
      }
    }
  }
}*/
h3 {
  margin: 0 0 1.5em;
  padding-bottom: .7em;
  color: #1a1a1a;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  position: relative;
}

h3 a {
  text-decoration: none;
}

h3 a:hover {
  text-decoration: underline;
}

h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #bfbfbf;
}

h4 {
  margin: 0 0 1.5em;
  /*padding-left: 15px;*/
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  color: #1a1a1a;
  font-weight: bold;
  /*&:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 1px;
    margin: auto;
    border-bottom: 2px solid $color_h4_text;
  }*/
}

h4 a {
  text-decoration: none;
}

h4 a:hover {
  text-decoration: underline;
}

.widget_wrap .widget_header {
  font-size: 16px;
  font-size: 1.6rem;
  color: #1a1a1a;
  padding: .7em 0 .7em 1em;
  background: rgba(212, 0, 0, 0.05);
}

.widget_wrap .widget_header a {
  text-decoration: none;
}

.widget_wrap .widget_header a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  h1:not(.title) {
    font-size: 22px;
    font-size: 2.2rem;
  }
  h1:not(.title):after {
    height: 2px;
  }
  h2 {
    font-size: 18px;
    font-size: 1.8rem;
  }
  h2:after, h2:before {
    height: 1px;
  }
  h3 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  h4 {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .widget_wrap .widget_header {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.main_visual {
  position: relative;
  table-layout: fixed;
}

.main_visual .main_visual_slick,
.main_visual .slick-track {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100% !important;
}

.main_visual .slick-track {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.main_visual .slick-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.main_visual .main_visual_slick {
  position: relative;
  margin: 0 auto;
}

.main_visual .main_visual_slick li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.main_visual .main_visual_slick li:not(.slick-active) {
  transition-delay: 0.5s !important;
}

.main_visual .main_visual_slick li img {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
  top: 0;
  height: 100%;
}

.main_visual .main_visual_copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_copy > li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.main_visual .main_visual_copy > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 56%;
  max-width: 560px;
  max-height: 560px;
  z-index: 52;
}

.main_visual .main_visual_arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_arrow .slick-prev {
  left: 25px;
}

.main_visual .main_visual_arrow .slick-next {
  right: 25px;
}

.main_visual .main_visual_arrow .slick-prev:before,
.main_visual .main_visual_arrow .slick-next:before {
  font-size: 30px;
  font-size: 3rem;
}

@media (max-width: 600px) {
  .main_visual .main_visual_arrow .slick-prev {
    left: 10px;
  }
  .main_visual .main_visual_arrow .slick-next {
    right: 10px;
  }
  .main_visual .main_visual_arrow .slick-prev:before,
  .main_visual .main_visual_arrow .slick-next:before {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .main_visual .main_visual_copy > li img {
    width: 90%;
  }
}

.main_visual .main_visual_slick {
  padding-top: 60% !important;
}

@media (min-width: 1000px) {
  .main_visual .main_visual_slick {
    padding-top: 0 !important;
    height: 600px !important;
  }
}

@media (min-width: 1920px) {
  .main_visual .main_visual_slick {
    padding-top: 31.25% !important;
  }
}

@media (max-width: 600px) {
  .main_visual .main_visual_slick {
    padding-top: 90% !important;
  }
}

.slick-dots li button {
  margin: 0 auto;
  background: #ccc;
}

.slick-dots li.slick-active button {
  background: #d40000;
}

.slide_scroll {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 45px;
  background: url(/wp-content/uploads/icon_scroll.png) center bottom no-repeat;
  z-index: 55;
  color: #fff;
  text-decoration: none;
}

.slide_scroll:hover {
  color: #fff;
}

.epress_search {
  padding: 3px;
  width: 100%;
}

.epress_search form {
  overflow: hidden;
  border: 4px solid #1a1a1a;
}

.epress_search input[type="text"],
.epress_search input[type="submit"] {
  margin: 0;
  padding: 0;
  float: left;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.epress_search input[type="text"] {
  background: #fff;
  color: #1a1a1a;
  padding: .3em .3em .3em .7em;
  width: 70%;
}

.epress_search input[type="submit"] {
  cursor: pointer;
  padding: .5em 1em;
  background: #1a1a1a;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  width: 30%;
}

@media (max-width: 767px) {
  header .global_nav > ul > li.gnav_search {
    position: static;
  }
  header .global_nav > ul > li.gnav_search > a {
    display: none !important;
  }
  header .global_nav > ul > li.gnav_search .epress_search {
    display: block !important;
    position: static !important;
    width: 100% !important;
    background: rgba(212, 0, 0, 0.9);
  }
}

/*
 * ヘッダーナビのデザイン(design của header navi)
 */
/*
  * ヘッダの検索表示タイプ(type hiển thị tìm kiếm của header)
  */
header .global_nav > ul > li.gnav_search {
  position: relative;
}

header .global_nav > ul > li.gnav_search a {
  display: block;
  background: url("/wp-content/uploads/search.png") no-repeat center center;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 30px;
  height: 30px;
}

header .global_nav > ul > li.gnav_search a:hover {
  border-bottom: 0;
}

header .global_nav > ul > li.gnav_search .epress_search {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

@media (min-width: 768px) {
  header .global_nav > ul > li.gnav_search .epress_search {
    width: 250px;
  }
}

header .global_nav > ul > li.gnav_search .epress_search form {
  background: #fff;
}

.article_list {
  padding: .5em;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .article_list {
    padding: 1em;
  }
}

.article_list:nth-child(even) {
  background: rgba(212, 0, 0, 0.05);
}

.article_list:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translate(-10px, -10px) rotate(45deg);
  transition: .3s;
}

.article_list:hover:before {
  background: #d40000;
}

.article_list .case_arrow {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: relative;
  height: 245px;
}

@media (max-width: 767px) {
  .article_list .case_arrow {
    height: 80px;
  }
}

.article_list .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  border-top: 5px solid #d40000;
  border-right: 5px solid #d40000;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: .2s;
}

@media (max-width: 767px) {
  .article_list .case_arrow:after {
    left: 0;
    bottom: 25%;
    border-top: 5px solid #d40000;
    border-right: 5px solid #d40000;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_list .span_12 {
  margin: 2% 0;
}

.pagination > * {
  display: inline-block;
  padding: .5em;
  border: 1px solid #ccc;
}

.pagination a {
  text-decoration: none;
  background: rgba(212, 0, 0, 0.05);
  transition: .3s;
}

.pagination a:hover {
  background: #fff;
}

.article_detail {
  background: rgba(212, 0, 0, 0.05);
  padding: 4%;
  margin-bottom: 4%;
  font-size: 15px;
  font-size: 1.5rem;
}

.article_detail .article_date {
  margin-bottom: 1em;
}

.article_detail .article_thumbnail {
  float: left;
  margin: 0 2% 2% 0;
}

.article_detail .article_content {
  float: none;
  width: 100%;
  margin-left: 0;
}

.article_detail .case_arrow {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: relative;
  height: 245px;
}

@media (max-width: 767px) {
  .article_detail .case_arrow {
    height: 80px;
  }
}

.article_detail .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  border-top: 5px solid #d40000;
  border-right: 5px solid #d40000;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: .2s;
}

@media (max-width: 767px) {
  .article_detail .case_arrow:after {
    left: 0;
    bottom: 25%;
    border-top: 5px solid #d40000;
    border-right: 5px solid #d40000;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_detail .span_12 {
  margin: 2% 0;
}

.epress_social {
  letter-spacing: -.40em;
  text-align: right;
  padding: 2%;
}

.epress_social > * {
  letter-spacing: normal;
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
}

.epress_pager {
  overflow: hidden;
  margin-bottom: 5%;
}

.epress_pager .epress_pager_prev {
  float: left;
}

.epress_pager .epress_pager_prev a {
  display: block;
  position: relative;
  padding-left: 15px;
  text-decoration: none;
}

.epress_pager .epress_pager_prev a:before, .epress_pager .epress_pager_prev a:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #d40000;
  transition: .2s;
}

.epress_pager .epress_pager_prev a:before {
  transform: translateY(-50%) rotate(-45deg);
  top: calc(50% - 2px);
}

.epress_pager .epress_pager_prev a:after {
  transform: translateY(-50%) rotate(-135deg);
  top: calc(50% + 3px);
}

.epress_pager .epress_pager_prev a:hover {
  text-decoration: underline;
}

.epress_pager .epress_pager_prev a:hover:before {
  left: 0;
}

.epress_pager .epress_pager_prev a:hover:after {
  left: 0;
}

.epress_pager .epress_pager_next {
  float: right;
}

.epress_pager .epress_pager_next a {
  display: block;
  position: relative;
  padding-right: 15px;
  text-decoration: none;
}

.epress_pager .epress_pager_next a:before, .epress_pager .epress_pager_next a:after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #d40000;
  transform-origin: center;
  transition: .2s;
}

.epress_pager .epress_pager_next a:before {
  transform: translateY(-50%) rotate(45deg);
  top: calc(50% - 2px);
}

.epress_pager .epress_pager_next a:after {
  transform: translateY(-50%) rotate(135deg);
  top: calc(50% + 3px);
}

.epress_pager .epress_pager_next a:hover {
  text-decoration: underline;
}

.epress_pager .epress_pager_next a:hover:before {
  right: 0;
}

.epress_pager .epress_pager_next a:hover:after {
  right: 0;
}

#commentform {
  background: transparent;
  padding: 2% 5%;
  border: 1px solid #333;
}

#commentform #comment {
  width: 100%;
}

#commentform #submit {
  background: #d40000;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 3px 10px;
  margin: 0 auto;
  display: table;
}

#commentform input[type="submit"] {
  background: #d40000;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-size: 1.4rem;
  padding: .3em 1em;
  margin: 0 auto;
  display: table;
  border-radius: 5px;
}

@media (min-width: 768px) {
  #commentform #submit {
    font-size: 18px;
    font-size: 1.8rem;
  }
  #commentform input[type="submit"] {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.calendar_wrap {
  position: relative;
  padding: 4em 1.8em 2em;
  display: inline-block;
  background: rgba(212, 0, 0, 0.05);
  font-size: 10px;
  font-size: 1rem;
}

.calendar_wrap caption {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  margin: auto;
}

.calendar_wrap table {
  border-spacing: 0;
  border-collapse: collapse;
}

.calendar_wrap table thead th {
  background: rgba(26, 26, 26, 0.7);
  color: #fff;
  border: 1px solid #1a1a1a;
  border-bottom: none;
}

.calendar_wrap table tfoot {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.calendar_wrap table tfoot #prev a {
  width: 26px;
  height: 25px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 40px;
}

.calendar_wrap table tfoot #prev a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #d40000;
  transition: .2s;
}

.calendar_wrap table tfoot #prev a:after {
  transform: translate(0, -50%) rotate(-45deg);
  border-left: 1px solid #d40000;
  left: 15%;
}

.calendar_wrap table tfoot #prev a:hover:after {
  left: 2%;
}

.calendar_wrap table tfoot #next a {
  width: 26px;
  height: 25px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  right: 40px;
}

.calendar_wrap table tfoot #next a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #d40000;
  transition: .2s;
}

.calendar_wrap table tfoot #next a:after {
  transform: translate(0, -50%) rotate(45deg);
  border-right: 1px solid #d40000;
  right: 15%;
}

.calendar_wrap table tfoot #next a:hover:after {
  right: 2%;
}

.calendar_wrap table tbody td {
  text-align: center;
  background: #fff;
  border: 1px solid #1a1a1a;
}

@media (min-width: 768px) {
  .column_sub .calendar_wrap {
    padding: 40px 5px 5px;
    width: 100%;
    margin: 0 auto 1em;
  }
  .column_sub .calendar_wrap caption {
    top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .column_sub .calendar_wrap table {
    width: 100%;
  }
  .column_sub .calendar_wrap table th, .column_sub .calendar_wrap table td {
    padding: 0.5em .2em;
  }
  .column_sub .calendar_wrap table tfoot #prev a {
    width: 18px;
    height: 18px;
    top: 0;
    left: 20px;
  }
  .column_sub .calendar_wrap table tfoot #next a {
    width: 18px;
    height: 18px;
    top: 0;
    right: 20px;
  }
}

@media (max-width: 767px) {
  .calendar_wrap {
    padding: 40px 5px 5px;
    width: 100%;
  }
  .calendar_wrap caption {
    top: 10px;
  }
  .calendar_wrap table {
    width: 100%;
  }
  .calendar_wrap table th, .calendar_wrap table td {
    padding: 0.5em .2em;
  }
  .calendar_wrap table tfoot #prev a {
    width: 12px;
    height: 12px;
    top: 0;
    left: 20px;
  }
  .calendar_wrap table tfoot #next a {
    width: 12px;
    height: 12px;
    top: 0;
    right: 20px;
  }
}

/*
 * ボタン矢印(mũi tên button)
 */
.add_arrow {
  display: block;
  position: relative;
}

.add_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 10%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #d40000;
  border-right: 1px solid #d40000;
  transform: translate(0, -50%) rotate(45deg);
  transition: .2s;
}

.add_arrow:hover:after {
  right: 7%;
}

/*
 * moreボタン雛形 (hình dạng button more)
 */
.more {
  display: inline-block;
  overflow: hidden;
  margin: auto;
}

.more a {
  display: inline-block;
  letter-spacing: normal;
  border: 1px solid #d40000;
  color: #d40000;
  padding: .3em 1.3em .3em 1em;
  margin-bottom: 1px;
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .more a {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

/*
 * ボタンエフェクト(button effect)
 */
@media (min-width: 768px) {
  .effect01 {
    overflow: hidden;
    position: relative;
  }
  .effect01:before {
    content: '';
    position: absolute;
    display: block;
    width: 140%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    background: #d40000;
    opacity: .3;
    transform: translateX(-120%) skewX(15deg);
    transition: transform .3s;
  }
  .effect01:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  }
  .effect01:hover:before {
    transform: translateX(-15%) skewX(15deg);
  }
}

header .global_nav > ul > li.has_under > a {
  position: relative;
}

header .global_nav > ul > li.has_under > a:before, header .global_nav > ul > li.has_under > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 15px;
  height: 2px;
  background: #fff;
  transition: .2s;
}

header .global_nav > ul > li.has_under > a:before {
  transform: translateY(-50%) rotate(-45deg);
  right: calc(5% - 5px);
}

header .global_nav > ul > li.has_under > a:after {
  transform: translateY(-50%) rotate(45deg);
  right: calc(5% + 5px);
}

header .global_nav > ul > li.has_under > a.menu_under_open:before {
  transform: translateY(-50%) rotate(45deg);
}

header .global_nav > ul > li.has_under > a.menu_under_open:after {
  transform: translateY(-50%) rotate(-45deg);
}

header .global_nav > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

header .global_nav > ul > li > ul > li {
  border-bottom: 1px solid #333;
}

@media (min-width: 768px) {
  header .global_nav > ul > li.has_under > a {
    padding-right: 1em;
  }
  header .global_nav > ul > li.has_under > a:before, header .global_nav > ul > li.has_under > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin: auto;
    width: 6px;
    height: 1px;
    background: #d40000;
    transition: .2s;
  }
  header .global_nav > ul > li.has_under > a:before {
    transform: translateY(-50%) rotate(-45deg);
    right: calc(5% - 2px);
  }
  header .global_nav > ul > li.has_under > a:after {
    transform: translateY(-50%) rotate(45deg);
    right: calc(5% + 2px);
  }
  header .global_nav > ul > li.has_under > a.menu_under_open:before {
    transform: translateY(-50%) rotate(45deg);
  }
  header .global_nav > ul > li.has_under > a.menu_under_open:after {
    transform: translateY(-50%) rotate(-45deg);
  }
  header .global_nav > ul > li.has_under > ul {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 100%;
    letter-spacing: -.40em;
    background: rgba(212, 0, 0, 0.9);
    z-index: 52;
  }
  header .global_nav > ul > li.has_under > ul > li {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: middle;
    width: 25%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
  }
  header .global_nav > ul > li.has_under > ul > li a {
    background: transparent;
    padding: 1em;
  }
}

.item_detail .item_list_link,
.item_list .item_list_link {
  display: block;
  position: relative;
  overflow: hidden;
}

.item_detail .item_list_link img,
.item_list .item_list_link img {
  position: absolute;
  top: -300%;
  bottom: -300%;
  left: -300%;
  right: -300%;
  margin: auto;
}

.item_list .item_list_link {
  padding-top: 75%;
}

.item_list .item_all_title {
  font-size: 18px;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .item_list .item_all_title {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.item_list .item_all_price {
  font-size: 12px;
  font-size: 1.2rem;
}

@media (max-width: 767px) {
  .item_list .item_all_price {
    font-size: 10px;
    font-size: 1rem;
  }
}

.item_list .item_all_price span {
  font-size: 18px;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .item_list .item_all_price span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.item_list.row_inline > .col {
  letter-spacing: normal;
  vertical-align: top;
  display: inline-block;
  float: none;
  margin-bottom: 5%;
}

.item_list.row_inline .span_1 {
  width: 6.5%;
}

.item_list.row_inline .span_1:nth-child(12n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_2 {
  width: 15%;
}

.item_list.row_inline .span_2:nth-child(6n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_3 {
  width: 23.5%;
}

.item_list.row_inline .span_3:nth-child(4n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_4 {
  width: 32%;
}

.item_list.row_inline .span_4:nth-child(3n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_5 {
  width: 40.5%;
}

.item_list.row_inline .span_5:nth-child(2n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_6 {
  width: 49%;
}

.item_list.row_inline .span_6:nth-child(2n+1) {
  margin-left: 0;
}

.item_list.row_inline .span_7 {
  width: 57.5%;
}

.item_list.row_inline .span_8 {
  width: 66%;
}

.item_list.row_inline .span_9 {
  width: 74.5%;
}

.item_list.row_inline .span_10 {
  width: 83%;
}

.item_list.row_inline .span_11 {
  width: 91.5%;
}

.item_list.row_inline .span_12 {
  width: 100%;
}

.item_detail .item_list_link {
  padding-top: 75%;
}

.item_detail .item_detail_title {
  font-size: 22px;
  font-size: 2.2rem;
}

@media (max-width: 767px) {
  .item_detail .item_detail_title {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.item_detail .span_12 {
  margin: 0;
}

.item_detail .item_detail_price {
  font-size: 12px;
  font-size: 1.2rem;
}

.item_detail .item_detail_price span {
  font-size: 22px;
  font-size: 2.2rem;
}

@media (max-width: 767px) {
  .item_detail .item_detail_price span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.item_detail form select {
  display: block;
  margin-top: .5em;
  font-size: 16px;
  font-size: 1.6rem;
}

table#cart,
table#maincart,
table#minicart {
  max-width: 100%;
}

table#cart caption,
table#maincart caption,
table#minicart caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

table#cart td,
table#cart th,
table#maincart td,
table#maincart th,
table#minicart td,
table#minicart th {
  border: 1px solid #1a1a1a;
}

table#cart th,
table#cart th.thcount,
table#cart th.thitem,
table#maincart th,
table#maincart th.thcount,
table#maincart th.thitem,
table#minicart th,
table#minicart th.thcount,
table#minicart th.thitem {
  background: rgba(26, 26, 26, 0.7);
  color: #fff;
}

table#cart td,
table#maincart td,
table#minicart td {
  background: transparent;
}

#login,
#submit,
#submit_entry,
#newentry,
#maincart,
#maincart_ajax,
#loginbutton,
.widget_wrap > form {
  margin-bottom: 1em !important;
}

#login p,
#submit p,
#submit_entry p,
#newentry p,
#maincart p,
#maincart_ajax p,
#loginbutton p,
.widget_wrap > form p {
  text-align: center;
}

#login input[type="submit"],
#submit input[type="submit"],
#submit_entry input[type="submit"],
#newentry input[type="submit"],
#maincart input[type="submit"],
#maincart_ajax input[type="submit"],
#loginbutton input[type="submit"],
.widget_wrap > form input[type="submit"] {
  display: inline-block;
  min-width: 0px;
  margin: 0;
  padding: 1em 1em;
  border: 1px solid;
  border-radius: 4px;
  line-height: 1;
  background: transparent;
  color: #d40000;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  min-width: 180px;
}

@media (max-width: 767px) {
  #login input[type="submit"],
  #submit input[type="submit"],
  #submit_entry input[type="submit"],
  #newentry input[type="submit"],
  #maincart input[type="submit"],
  #maincart_ajax input[type="submit"],
  #loginbutton input[type="submit"],
  .widget_wrap > form input[type="submit"] {
    min-width: 35%;
    margin-bottom: 10px;
  }
}

#login input[type="submit"]:hover,
#submit input[type="submit"]:hover,
#submit_entry input[type="submit"]:hover,
#newentry input[type="submit"]:hover,
#maincart input[type="submit"]:hover,
#maincart_ajax input[type="submit"]:hover,
#loginbutton input[type="submit"]:hover,
.widget_wrap > form input[type="submit"]:hover {
  background: #d40000;
  color: #fff;
}

#login input[type="submit"]:active, #login input[type="submit"]:focus,
#submit input[type="submit"]:active,
#submit input[type="submit"]:focus,
#submit_entry input[type="submit"]:active,
#submit_entry input[type="submit"]:focus,
#newentry input[type="submit"]:active,
#newentry input[type="submit"]:focus,
#maincart input[type="submit"]:active,
#maincart input[type="submit"]:focus,
#maincart_ajax input[type="submit"]:active,
#maincart_ajax input[type="submit"]:focus,
#loginbutton input[type="submit"]:active,
#loginbutton input[type="submit"]:focus,
.widget_wrap > form input[type="submit"]:active,
.widget_wrap > form input[type="submit"]:focus {
  outline: none;
}

.widget_wrap > form input[type="submit"] {
  display: block;
  margin-top: 5px;
}

#sidecatlist ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidecatlist li {
  padding: 0.5em 0.7em;
  border-bottom: 1px solid #ccc;
}

#sidecatlist li:last-child {
  border-bottom: 0;
}

#sidecatlist li a {
  display: block;
  text-decoration: none;
}

#sidecatlist li a:hover {
  text-decoration: underline;
}

.recommend {
  padding: 5% 0;
  margin-bottom: 0;
  /* slick */
}

.recommend .slick-slider {
  overflow: hidden;
}

.recommend .slick-slide {
  margin: 0 0.2%;
}

.recommend .slick-slide a:focus {
  outline: 0;
}

.recommend .slick-next,
.recommend .slick-prev {
  z-index: 10;
}

.recommend .slick-next:before,
.recommend .slick-prev:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 10%;
  margin: auto;
  width: 20px;
  height: 20px;
  z-index: 10;
  border-top: 3px solid #1a1a1a;
  border-right: 3px solid #1a1a1a;
  transition: 0.2s;
}

.recommend .slick-prev {
  left: 40px;
}

.recommend .slick-prev:before {
  left: 5%;
  transform: translate(0, -50%) rotate(-135deg);
}

.recommend .slick-next {
  right: 40px;
}

.recommend .slick-next:before {
  right: 5%;
  transform: translate(0, -50%) rotate(45deg);
}

.recommend .more {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.recommend .more a {
  display: block;
  margin: 0 auto;
  max-width: 235px;
  width: 100%;
}

.recommend .item_photo a {
  position: relative;
  display: block;
  padding-top: 75%;
  overflow: hidden;
}

.recommend .item_photo a img {
  max-width: none;
  width: auto;
  position: absolute;
  top: -300%;
  left: -300%;
  right: -300%;
  bottom: -300%;
  margin: auto;
  height: 100%;
}

.pagetop {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
}

.pagetop a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  background: #d81919;
}

@media (min-width: 768px) {
  .pagetop a {
    width: 70px;
    height: 70px;
  }
}

.pagetop a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: translate(-50%, -20%) rotate(45deg);
  transition: .2s;
}

footer.high .pagetop {
  position: absolute;
  bottom: auto;
  right: 5%;
  top: 0;
  margin: 0;
  transform: translateY(-50%);
}

/*
 * ページトップのデザインタイプ(type design của top page)
 */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: #d40000;
  font-size: 20px;
  font-size: 2rem;
}

.text-xxlarge {
  font-size: 24px;
  font-size: 2.4rem;
}

.text-xlarge {
  font-size: 20px;
  font-size: 2rem;
}

.text-large {
  font-size: 16px;
  font-size: 1.6rem;
}

.text-slarge {
  font-size: 16px;
  font-size: 1.6rem;
}

.text-small {
  font-size: 12px;
  font-size: 1.2rem;
}

.text-xsmall {
  font-size: 10px;
  font-size: 1rem;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-primary {
  color: #d40000 !important;
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-xxlarge {
    font-size: 36px;
    font-size: 3.6rem;
  }
  .text-xlarge {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-large {
    font-size: 20px;
    font-size: 2rem;
  }
  .text-small {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .text-xsmall {
    font-size: 10px;
    font-size: 1rem;
  }
  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

.arial {
  font-family: "Arial", arial !important;
}

.arial_n {
  font-family: "Arial Narrow", arial !important;
}

.color_pri {
  color: #d40000;
}

.mb0 {
  margin-bottom: 0 !important;
}

h1:not(.title) {
  text-align: center;
  position: relative;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  height: 160px;
  color: #fff;
  background: url(/wp-content/uploads/h1_bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 55px;
  margin: 0 0 .5em;
}

h1:not(.title) a {
  text-decoration: none;
}

h1:not(.title) a:hover {
  text-decoration: underline;
}

h2 {
  display: table;
  table-layout: fixed;
  margin: 0 auto 1.5em !important;
  padding-bottom: 25px;
  color: #1a1a1a;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  position: relative;
}

h2 a {
  text-decoration: none;
}

h2 a:hover {
  text-decoration: underline;
}

h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 78px;
  height: 2px;
  background: url(/wp-content/uploads/h2_line.jpg) no-repeat center;
}

.top_section1 {
  font-weight: bold;
  font-size: 16px;
  position: relative;
  line-height: 2;
  background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding: 16em 0;
  margin: 0 auto;
}

.top_section1:before {
  content: '';
  display: block;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/wp-content/uploads/beef01.png) no-repeat center;
  width: 262px;
  height: 200px;
}

.top_section1:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -15%;
  left: 50%;
  transform: translate(-50%, -80%);
  height: 2px;
  background: url(/wp-content/uploads/beef2.png) no-repeat center;
  width: 270px;
  height: 206px;
}

.top_section1 .col {
  position: relative;
  z-index: 5;
}

.top_section2 {
  position: relative;
  line-height: 2;
  background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
  padding: 5em 0;
  margin: 0 auto !important;
}

.top_section2 .col {
  position: relative;
}

.top_section2 a .borderbox {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.top_section2 a:hover .borderbox {
  border: 5px solid #1a1a1a;
}

.top_section2 .fig_box1 {
  max-width: 300px;
  margin: 0 auto;
}

.top_section2 .fig_box2 {
  max-width: 300px;
  margin: 0 auto;
  height: 180px;
  padding-top: 75px;
  background: #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
}

.top_section2 .fig_box3 {
  max-width: 300px;
  margin: 0 auto;
  height: 180px;
  padding-top: 75px;
  background: #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
}

.top_section2 .fig_box4 {
  max-width: 300px;
  margin: 0 auto;
  height: 180px;
  padding-top: 75px;
  background: #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
}

.top_section2 .fig_text1 {
  background: #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
  height: 90px;
  width: 100%;
  margin: 0;
}

.top_section2 .fig_text2 {
  background: #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
  height: 90px;
  width: 100%;
  margin: 0;
}

.top_section2 .fig_text3 {
  background: #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
  height: 90px;
  width: 100%;
  margin: 0;
}

.top_section3 figcaption {
  text-align: center !important;
}

.top_section4 {
  margin: 0 auto;
}

.top_section4 .news .news_bg {
  background: rgba(212, 0, 0, 0.05);
}

.top_section4 .news.typeC .news_bg {
  padding: 1em;
  background-color: #fff;
}

.top_section4 .news h2 {
  color: #1a1a1a;
}

.top_section4 .news dl {
  margin: 0;
}

.top_section4 .news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.top_section4 .news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  /*border-bottom: $style_news_border;*/
}

.top_section4 .news dl dd a {
  text-decoration: none;
}

.top_section4 .news dl dd a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .top_section4 .news.typeA > div {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  .top_section4 .news.typeA > div .heading {
    display: table-cell;
    width: 23.5%;
    vertical-align: middle;
  }
  .top_section4 .news.typeA h2 {
    margin: 0;
    padding: 0;
  }
  .top_section4 .news.typeA h2:after {
    display: none;
  }
  .top_section4 .news.typeA .content {
    display: table-cell;
    overflow: hidden;
    width: 74.5%;
    padding-left: 2%;
    vertical-align: middle;
  }
  .top_section4 .news.typeC .news_bg {
    height: 400px;
    padding: 10px;
  }
  .top_section4 .news.typeC dl {
    max-height: 350px;
  }
  .top_section4 .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .top_section4 .news dl dt {
    width: 100px;
    clear: left;
    float: left;
    margin: 0;
    padding: 1em 0;
  }
  .top_section4 .news dl dd {
    margin: 0;
    padding: 1em 0 1em 0;
  }
  .top_section4 .news dl dt + dd {
    padding-left: 120px;
  }
}

.top_section5 {
  margin: 0 auto;
  padding: 4em 0;
  background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
}

.top_section6 {
  border-top: 1px solid #1a1a1a;
  padding-top: 2em;
}

.top_section6 .sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0px;
  font-size: 0rem;
  margin: auto;
}

.top_section6 .sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

.top_section6 .sns_icons li {
  display: inline-block;
}

.top_section6 .sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.top_section6 .sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}

.top_section6 .sns_icons a.icon_facebook {
  background-image: url(/wp-content/uploads/facebook.png) !important;
}

.top_section6 .sns_icons a.icon_twitter {
  background-image: url(/wp-content/uploads/twitter.png) !important;
}

.top_section6 footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .top_section6 .sns_icons {
    margin: 0;
  }
  .top_section6 .sns_icons.center {
    margin: auto;
  }
  .top_section6 .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .top_section6 .sns_icons a {
    width: 50px;
    height: 50px;
  }
  .top_section6 footer .sns_icons {
    margin-top: 30px;
  }
}

.top_section6 {
  border-top: 1px solid #1a1a1a;
  padding-top: 2em;
}

.top_section6 .sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0px;
  font-size: 0rem;
  margin: auto;
}

.top_section6 .sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

.top_section6 .sns_icons li {
  display: inline-block;
  padding: 0 1em;
}

.top_section6 .sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.top_section6 .sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}

.top_section6 .sns_icons a.icon_facebook {
  background-image: url(/wp-content/uploads/facebook.png) !important;
}

.top_section6 .sns_icons a.icon_twitter {
  background-image: url(/wp-content/uploads/twitter.png) !important;
}

.top_section6 footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .top_section6 .sns_icons {
    margin: 0;
  }
  .top_section6 .sns_icons.center {
    margin: auto;
  }
  .top_section6 .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .top_section6 .sns_icons a {
    width: 50px;
    height: 50px;
  }
  .top_section6 footer .sns_icons {
    margin-top: 30px;
  }
}

.color_box1 {
  font-weight: bold;
  color: #fff;
  background-color: #d40000;
  padding: 15px 10px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .primary_header .row:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .nav_custom {
    margin: 0 !important;
  }
  .global_nav {
    background-color: #1a1a1a;
  }
  .top_section1 {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    line-height: 2;
    background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding: 5em 0;
    margin: 0 auto;
  }
  .top_section1:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    transform: translateY(-50%);
    background: url(/wp-content/uploads/beef01.png) no-repeat center;
    width: 262px;
    height: 200px;
  }
  .top_section1:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: auto;
    transform: translateY(-50%);
    height: 2px;
    background: url(/wp-content/uploads/beef2.png) no-repeat center;
    width: 270px;
    height: 206px;
  }
  .top_section1 .col {
    position: relative;
    z-index: 5;
  }
  .top_section2 {
    position: relative;
    line-height: 2;
    background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding: 5em 0;
    margin: 0 auto !important;
  }
  .top_section2 .col {
    position: relative;
  }
  .top_section2 a .borderbox {
    width: 250 px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .top_section2 a:hover .borderbox {
    border: 5px solid #1a1a1a;
  }
  .top_section2 .fig_box1 {
    max-width: 250px;
    margin: 0 auto;
  }
  .top_section2 .fig_box2 {
    max-width: 250px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
  }
  .top_section2 .fig_box3 {
    max-width: 250px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
  }
  .top_section2 .fig_box4 {
    max-width: 250px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
  }
  .top_section2 .fig_text1 {
    background: #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
    background-size: 70%;
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .top_section2 .fig_text2 {
    background: #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
    background-size: 70%;
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .top_section2 .fig_text3 {
    background: #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
    background-size: 70%;
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .recommend {
    padding: 5% 0;
    margin-bottom: 0;
    /* slick */
  }
  .recommend .slick-slider {
    overflow: hidden;
  }
  .recommend .slick-slide {
    margin: 0 0.2%;
  }
  .recommend .slick-slide a:focus {
    outline: 0;
  }
  .recommend .slick-next,
  .recommend .slick-prev {
    z-index: 10;
  }
  .recommend .slick-next:before,
  .recommend .slick-prev:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 10%;
    margin: auto;
    width: 20px;
    height: 20px;
    z-index: 10;
    border-top: 3px solid #1a1a1a;
    border-right: 3px solid #1a1a1a;
    transition: 0.2s;
  }
  .recommend .slick-prev {
    left: 40px;
    top: 35%;
  }
  .recommend .slick-prev:before {
    left: 5%;
    transform: translate(0, -50%) rotate(-135deg);
  }
  .recommend .slick-next {
    right: 40px;
    top: 35%;
  }
  .recommend .slick-next:before {
    right: 5%;
    transform: translate(0, -50%) rotate(45deg);
  }
  .recommend .more {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .recommend .more a {
    display: block;
    margin: 0 auto;
    max-width: 235px;
    width: 100%;
  }
  .recommend .item_photo a {
    position: relative;
    display: block;
    padding-top: 75%;
    overflow: hidden;
  }
  .recommend .item_photo a img {
    max-width: none;
    width: auto;
    position: absolute;
    top: -300%;
    left: -300%;
    right: -300%;
    bottom: -300%;
    margin: auto;
    height: 100%;
  }
  .color_box1 {
    width: 450px !important;
    padding: 15px 10px;
  }
}

@media (min-width: 1024px) {
  .top_section1 {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    line-height: 2;
    background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding: 5em 0;
    margin: 0 auto;
  }
  .top_section1:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    background: url(/wp-content/uploads/beef01.png) no-repeat center;
    width: 262px;
    height: 200px;
  }
  .top_section1:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    height: 2px;
    background: url(/wp-content/uploads/beef2.png) no-repeat center;
    width: 270px;
    height: 206px;
  }
  .top_section1 .col {
    position: relative;
    z-index: 5;
  }
}

@media (min-width: 920px) {
  .top_section2 {
    position: relative;
    line-height: 2;
    background: url(/wp-content/uploads/bg_img01.jpg) repeat center;
    padding: 5em 0;
    margin: 0 auto !important;
  }
  .top_section2 .col {
    position: relative;
  }
  .top_section2 a .borderbox {
    width: 300px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .top_section2 a:hover .borderbox {
    border: 5px solid #1a1a1a;
  }
  .top_section2 .fig_box1 {
    max-width: 300px;
    margin: 0 auto;
  }
  .top_section2 .fig_box2 {
    max-width: 300px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img004.jpg) no-repeat center;
  }
  .top_section2 .fig_box3 {
    max-width: 300px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img005.jpg) no-repeat center;
  }
  .top_section2 .fig_box4 {
    max-width: 300px;
    margin: 0 auto;
    height: 180px;
    padding-top: 75px;
    background: #1a1a1a url(/wp-content/uploads/top_img006.jpg) no-repeat center;
  }
  .top_section2 .fig_text1 {
    background: #1a1a1a url(/wp-content/uploads/title01.png) no-repeat center;
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .top_section2 .fig_text2 {
    background: #1a1a1a url(/wp-content/uploads/title02.png) no-repeat center;
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .top_section2 .fig_text3 {
    background: #1a1a1a url(/wp-content/uploads/title03.png) no-repeat center;
    height: 90px;
    width: 100%;
    margin: 0;
  }
}
