@charset "utf-8";

.news {
  // padding: 4% 0;
  .news_bg {
    background: $color_news_bg;
  }
  &.typeC {
    .news_bg {
      padding: 1em;
    }
  }
  h2 {
    color: $color_news_header;
  }
  dl {
    margin: 0;
    dt {
      margin: 0.5em 0 0;
      padding: 0 1em;
      font-weight: normal;
    }
    dd {
      margin: 0.5em 0;
      padding: 0 1em 0.5em;
      /*border-bottom: $style_news_border;*/
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media ( min-width : 768px ) {

  .news {
    &.typeA {
      > div {
        display: table;
        width: 100%;
        margin: 0 auto;
        .heading {
          display: table-cell;
          width: 23.5%;
          vertical-align: middle;
        }
      }
      h2 {
        margin: 0;
        padding: 0;
        &:after {
          display: none;
        }
      }
      .content {
        display: table-cell;
        overflow: hidden;
        width: 74.5%;
        padding-left: 2%;
        vertical-align: middle;
      }
    }
    &.typeC {
      .news_bg {
        height: 540px;
        padding: 20px;
      }
      dl {
        max-height: 500px;
      }
    }
    dl {
      overflow-y: auto;
      max-height: 200px;
      dt {
        width: 100px;
        clear: left;
        float: left;
        margin: 0;
        padding: 1em 0;
      }
      dd {
        margin: 0;
        padding: 1em 0 1em 0;
      }
      dt + dd {
        padding-left: 120px;
      }
    }
  }

}
